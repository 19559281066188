export const SettingsReducers = (state = {}, action) => {
  switch (action.type) {
    case "SAVE_USER_SETTINGS":
      localStorage.setItem("userSettings", JSON.stringify(state));
      return { ...state };

    case "UPDATE_USER_SETTING":
      return {
        ...state,
        [action.name]: action.value
      };

    default:
      return state;
  }
};
