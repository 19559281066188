import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { CustomDialog } from "components";
import { API } from "constants/variables";
import { Icon } from "office-ui-fabric-react/lib/Icon";

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class OrderRegistraionConfirmation extends Component {
    constructor(props) {
      super(props);

      this.state = {
        showConfirmMessage: false
      };
    }
    render() {
      let currentStatus = this.props.application.registrationFormStatus;
      let orderIds = this.props.application.newOrderIds;
      let title = "Order(s) created";
      // let attachment = "";
      let status = "";

      switch (currentStatus) {
        case -1:
          status = "Failed creating order in TransFleet";
          title = "Failed creating order(s).";
          break;
        case -2:
          status = "Uploading attachments to TransFleet failed.";
          title = "Order(s) created, but attachments failed.";
          break;

        case -3:
          status = this.props.application.message;
          title = "Failed creating order(s).";
          break;

        case 2:
          // attachment = "Attachments successfully uploaded";
          title = "Order(s) and attachments created";
          break;

        default:
          status = "";
          // attachment = "";
          break;
      }

      return (
        <CustomDialog
          title={title}
          hidden={!this.props.show}
          ConfirmButtonText="Ok"
          onClickConfirmButton={() => this.successDialogSupress()}
          onDismiss={() => this.props.onDismiss()}
          modalProps={{
            isBlocking: true
          }}
        >
          {status && <div>{status}</div>}
          {this.orderIds(orderIds)}
        </CustomDialog>
      );
    }

    orderIds = orderIds => {
      if (orderIds !== undefined && Array.isArray(orderIds)) {
        let orderIdElement = orderIds.map((item, id) => {
          return (
            <tr key={id}>
              <td>{item}</td>
              <td style={{ textAlign: "center" }}>
                <Icon
                  iconName="DownloadDocument"
                  title="Freight Note"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.downloadReport(item)}
                />
              </td>
              <td style={{ textAlign: "center" }}>
                <Icon
                  iconName="CustomList"
                  title="Parcel Label"
                  style={{
                    cursor: "pointer",
                    marginLeft: "10px"
                  }}
                  onClick={() => this.downloadParcelLabel(item)}
                />
              </td>
            </tr>
          );
        });

        return (
          <div>
            The following order(s) have been created in TransFleet.
            <div style={{ marginTop: "5px" }}>
              <table width="100%">
                <tbody>
                  <tr>
                    <th>OrderId</th>
                    <th style={{ textAlign: "center" }}>Freight Note</th>
                    <th style={{ textAlign: "center" }}>Parcel Label</th>
                  </tr>
                  {orderIdElement}
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: "5px" }} className="ms-font-s">
              Click on the document to download PDF
            </div>
          </div>
        );
      }
    };

    successDialogSupress = () => {
      this.props.functions.application.clearRegistrationStatus();
      this.props.functions.application.toggleRegistrationForm(false);
      this.props.onDismiss();
    };

    downloadReport = async orderId => {
      const response = await fetch(
        `${API}/api/v1/Orders/GetDefaultOrderReport/${orderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer  ${localStorage.getItem("token")}`
          }
        }
      );
      const blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var tempElement = document.createElement("a");
      tempElement.href = url;
      tempElement.download = `${orderId}.pdf`;
      tempElement.click();
    };

    downloadParcelLabel = async orderId => {
      const response = await fetch(
        `${API}/api/v1/Orders/GetDefaultParcelReport/${orderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `bearer  ${localStorage.getItem("token")}`
          }
        }
      );
      const blob = await response.blob();
      var url = window.URL.createObjectURL(blob);
      var tempElement = document.createElement("a");
      tempElement.href = url;
      tempElement.download = `ParcelLabel_${orderId}.pdf`;
      tempElement.click();
    };
  }
);
