"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
var _react = require("react");
var _react2 = _interopRequireDefault(_react);
require("./Card.css");
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }
  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}
function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }
  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}
var Card = function (_Component) {
  _inherits(Card, _Component);
  function Card(props) {
    _classCallCheck(this, Card);
    var _this = _possibleConstructorReturn(this, (Card.__proto__ || Object.getPrototypeOf(Card)).call(this, props));
    _this.componentWillUpdate = function (nextProps, nextState) {
      if (nextProps.isHidden && !_this.props.isHidden || nextState.isHidden && !_this.state.isHidden) {
        setTimeout(function () {
          _this.setState({
            shouldUnmount: true
          });
        }, 1000);
      }
    };
    _this.componentWillUnmount = function () {
      // ryddopp hvis noe skal ryddes opp
    };
    _this._onClose = function () {
      if (_this.props.onClose) {
        _this.props.onClose();
      } else {
        _this.setState({
          isHidden: true
        });
      }
    };
    _this.state = {
      isHidden: false,
      shouldUnmount: false
    };
    return _this;
  }
  _createClass(Card, [{
    key: "render",
    value: function render() {
      var _props = this.props,
        onClose = _props.onClose,
        closable = _props.closable,
        title = _props.title,
        description = _props.description,
        hideDivider = _props.hideDivider,
        isHidden = _props.isHidden;
      var animation = isHidden || this.state.isHidden ? "animated fadeOut" : null;
      if (this.state.shouldUnmount) {
        return null;
      }
      return _react2.default.createElement("div", {
        className: "card-body " + animation
      }, (onClose || closable) && _react2.default.createElement("div", {
        className: "card-closebutton",
        onClick: this._onClose
      }, _react2.default.createElement("i", {
        className: "ms-Icon ms-Icon--ChromeClose",
        "aria-hidden": "true"
      })), _react2.default.createElement("div", {
        className: "card-container"
      }, _react2.default.createElement("div", {
        className: "card-header ms-fontWeight-semibold ms-fontSize-l"
      }, title), description && _react2.default.createElement("div", {
        className: "card-description ms-fontSize-sPlus"
      }, description), (description || title) && !hideDivider && _react2.default.createElement("div", {
        className: "card-divider"
      }), _react2.default.createElement("div", {
        className: "card-content ms-fontSize-mPlus"
      }, this.props.children)));
    }
  }]);
  return Card;
}(_react.Component);
exports.default = Card;