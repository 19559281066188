import { TimePicker } from "components";
import moment from "moment";
import { DatePicker, DayOfWeek, FirstWeekOfYear, TextField, VirtualizedComboBox } from "office-ui-fabric-react";
import * as React from "react";

export const Place = (props) => {
  const OnDropdownUpdate = (value) => {
    props.onDropdownUpdate(value);
  };

  const OnUpdate = (field, value) => {
    if (field === "Date") {
      if (props.dateLatest === undefined || props.dateLatest < value) {
        props.onUpdate(`${props.typeOfPlace}DateLatest`, value);
      }
    }

    props.onUpdate(`${props.typeOfPlace}${field}`, value);
  };

  const updatePlace = (manualPlace) => {
    try {
      props.onUpdatePlace(manualPlace);
    } catch (e) {
      console.error(e);
    }
  };

  const capitalizeWord = (word) => {
    return word[0].toUpperCase() + word.substring(1);
  };

  return (
    <div className="registration-column">
      <b>{capitalizeWord(props.title)} place</b>
      <div className="registration-collectplace-frame class-a">
        {/* Left */}
        <div>
          <VirtualizedComboBox
            label="Search registered places"
            disabled={props.selectedCustomer ? false : true}
            ariaLabel="Basic ComboBox example"
            useComboBoxAsMenuWidth={true}
            allowFreeform={true}
            autoComplete="on"
            options={props.customerLocations}
            onChange={(e, value) => {
              OnDropdownUpdate(value);
            }}
            selectedKey={props.selectedPlace !== undefined ? props.selectedPlace.id : -1}
          />

          <div className="registration-section-form class-b" style={{ marginTop: "-5px" }}>
            <div className="registration-section-item">
              <DatePicker
                id="date"
                label={capitalizeWord(props.title) + " date"}
                firstDayOfWeek={DayOfWeek.Monday}
                firstWeekOfYear = {FirstWeekOfYear.FirstFourDayWeek}
                value={props.date}
                onSelectDate={(value) => OnUpdate("Date", value)}
                formatDate={(date) => moment(date).format("DD.MM.YYYY")}
                showWeekNumbers={true}
                textField={{
                  validateOnLoad: true,
                  onGetErrorMessage: (value) => {
                    if (value === "") {
                      return `${capitalizeWord(props.title)} date is required`;
                    }
                  },
                }}
              />
            </div>
            <div>
              <TimePicker
                onChanged={(e, value) => {
                  OnUpdate("Time", value);
                }}
                onValidation={(valid) => {
                  props.onTimeValidation(`${props.typeOfPlace}TimeLatest`, valid);
                }}
              />
            </div>
          </div>

          <div className="registration-section-form">
            <div className="registration-section-item">
              <DatePicker
                id="dateLatest"
                label={capitalizeWord(props.title) + " date latest"}
                firstDayOfWeek={DayOfWeek.Monday}
                firstWeekOfYear = {FirstWeekOfYear.FirstFourDayWeek}
                value={props.dateLatest}
                minDate={props.date}
                textField={{
                  validateOnLoad: true,
                  onGetErrorMessage: (value) => {
                    if (value === "") {
                      return `${capitalizeWord(props.title)} date latest is required`;
                    }
                  },
                }}
                onSelectDate={(value) => {
                  OnUpdate("DateLatest", value);
                }}
                formatDate={(date) => moment(date).format("DD.MM.YYYY")}
                // minDate={props.date}
                // initialPickerDate={new Date()}
                // disableAutoFocus
              />
            </div>
            <div>
              <TimePicker
                id={`${props.typeOfPlace}TimeLatest`}
                onChanged={(e, value) => {
                  OnUpdate("TimeLatest", value);
                }}
                onValidation={(valid) => {
                  props.onTimeValidation(`${props.typeOfPlace}TimeLatest`, valid);
                }}
              />
            </div>
          </div>
          {props.showPreadvicePhone && (
            <div className="registration-section-item">
              <TextField
                id={`${props.typeOfPlace}PreadvicedPhone`}
                label="SMS preadvise phone"
                // disabled={props.selectedCustomer ? false : true}
                defaultValue={props.preadvicePhoneValue && props.preadvicePhoneValue}
                onChange={(e, value) => {
                  props.onPreadvicePhoneChange(value);
                }}
              />
            </div>
          )}
        </div>

        {/* Right */}
        <div>
          <TextField
            id={`${props.typeOfPlace}Name`}
            label="Name"
            disabled={props.selectedCustomer !== undefined ? false : true}
            value={props.selectedPlace !== undefined ? props.selectedPlace.name : props.manualPlace.name}
            onChange={(e, value) => {
              const manualPlace = Object.assign({}, props.manualPlace);
              manualPlace.name = value;
              updatePlace(manualPlace);
            }}
          />

          <TextField
            id={`${props.typeOfPlace}Address`}
            label="Address"
            disabled={props.selectedCustomer ? false : true}
            value={props.selectedPlace !== undefined ? props.selectedPlace.address.street : props.manualPlace.address}
            onChange={(e, value) => {
              const manualPlace = Object.assign({}, props.manualPlace);
              manualPlace.address = value;
              updatePlace(manualPlace);
            }}
          />

          <div className="registration-section-form">
            <div className="registration-section-item">
              <TextField
                id={`${props.typeOfPlace}Contact`}
                label="Contact"
                disabled={props.selectedCustomer ? false : true}
                onChange={(e, value) => {
                  OnUpdate("PlaceContact", value);
                }}
              />
            </div>
            <div className="registration-section-item">
              <TextField
                id={`${props.typeOfPlace}Phone`}
                label="Phone"
                disabled={props.selectedCustomer ? false : true}
                onChange={(e, value) => {
                  OnUpdate("PlacePhone", value);
                }}
              />
            </div>
          </div>
          <div className="registration-section-form">
            <div className="registration-section-item">
              <TextField
                id={`${props.typeOfPlace}ZipCode`}
                label="ZIP-code"
                disabled={props.selectedCustomer ? false : true}
                value={
                  props.selectedPlace !== undefined
                    ? props.selectedPlace.address.postalCode
                    : props.manualPlace.postalCode
                }
                onChange={(e, value) => {
                  const manualPlace = Object.assign({}, props.manualPlace);
                  manualPlace.postalCode = value;
                  updatePlace(manualPlace);
                }}
              />
            </div>
            <div className="registration-section-item">
              <TextField
                id={`${props.typeOfPlace}City`}
                label="City"
                disabled={props.selectedCustomer ? false : true}
                value={
                  props.selectedPlace !== undefined
                    ? props.selectedPlace.address.postalDistrict
                    : props.manualPlace.postalDistrict
                }
                onChange={(e, value) => {
                  const manualPlace = Object.assign({}, props.manualPlace);
                  manualPlace.postalDistrict = value;
                  updatePlace(manualPlace);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
