import TransFleetObject from "./TransFleetObject";
import { API } from "constants/variables";

export default class OrderClass extends TransFleetObject {
  constructor(id) {
    super(id);
    this.apiQuery = `Orders/GetOrderDetails/${id}`;
  }

  GetStatusHistory = () => {
    let queryUrl = `${API}/api/v1.0/Orders/GetStatusHistory/${this.id}`;
    return fetch(queryUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        if (response.ok && response.status !== 204) {
          // Request success
          return response.json().then(result => {
            return result;
          });
        } else {
          if (response.status !== 204) {
            response.text().then(value => {
              console.error(value);
            });
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  GetOrderAttachmentsList = () => {
    let queryUrl = `${API}/api/v1.0/Orders/GetOrderAttachmentLists/${this.id}`;
    return fetch(queryUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        if (response.ok && response.status !== 204) {
          // Request success
          return response.json().then(result => {
            return result;
          });
        } else {
          if (response.status !== 204) {
            response.text().then(value => {
              console.error(value);
            });
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  GetOrderSignatures = () => {
    let queryUrl = `${API}/api/v1.0/Orders/GetSignatures/${this.id}`;
    return fetch(queryUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        if (response.ok && response.status !== 204) {
          // Request success w
          return response.json().then(result => {
            return result;
          });
        } else {
          if (response.status !== 204) {
            response.text().then(value => {
              console.error(value);
            });
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}
