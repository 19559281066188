import { Dropdown } from "office-ui-fabric-react";
import * as React from "react";

export const StatusSelection = props => {
  return (
    <div className="searchform-row">
      <div className="searchform-table">
        <div className="searchform-row">
          <div
            className="searchform-inputfield searchform-cell"
            style={{ width: "50%" }}
          >
            <Dropdown
              placeholder="Select status"
              id="StatusDropdown"
              iconProps={{ iconName: "Flag" }}
              selectedKey={props.selectedMainStatus}
              options={createStatusDrownDownOptions(props.statusConfig)}
              onChange={props.onMainStatusChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function createStatusDrownDownOptions(status) {
  let options = [{ key: "-1", text: "All Statuses" }];
  status.forEach((item, ix) => {
    if (
      options.findIndex((lookupItem, ix) => {
        return lookupItem.key === item.cwStatusId;
      }) === -1
    ) {
      options.push({ key: item.cwStatusId, text: item.description });
    }
  });
  return options;
}
