import { API } from "constants/variables";
import moment from "moment";
import {
  DatePicker,
  DayOfWeek,
  DefaultButton,
  PrimaryButton,
  TextField
} from "office-ui-fabric-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { mapDispatchToProps } from "reduxstore";
import "./SearchForm.css";
import { StatusSelection } from "./StatusSelection";

const mapStateToProps = state => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class SearchForm extends Component {
      constructor(props) {
        super(props);
        this.initialState = {
          txtSearch: "",
          txtPostalFrom: "",
          txtPostalTo: "",
          txtDateFrom: new Date(new Date().setDate(new Date().getDate() - 31)),
          txtDateTo: new Date(new Date().setDate(new Date().getDate() + 1)),
          ddMainStatus: -1,
          ddSubStatus: -1,
          selectedMainStatus: -1,
          selectedSubStatus: -1
        };

        this.state = {
          searchDaysBefore: 31,
          searchDaysAfter: 1,
          txtSearch:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.searchString
              : "",
          txtPostalFrom:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.postalCodeFrom === 0
                ? ""
                : this.props.data.searchCriterias.postalCodeFrom
              : "",
          txtPostalTo:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.postalCodeTo === 0
                ? ""
                : this.props.data.searchCriterias.postalCodeTo
              : "",
          txtDateFrom: undefined,
          txtDateTo: undefined,
          ddMainStatus:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.status
              : -1,
          selectedMainStatus:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.status.toString()
              : -1,
          ddSubStatus:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.substatus
              : -1,
          selectedSubStatus:
            this.props.data.searchCriterias !== undefined
              ? this.props.data.searchCriterias.substatus.toString()
              : -1
        };
      }

      componentDidMount = () => {
        this.props.functions.data.getStatusConfig(this.props.auth.departmentId);
        this.getDepartmentConfig();
      };

      render() {
        return (
          <div>
            <div className="searchform-table">
              <div className="searchform-row">
                <div className="searchform-inputfield searchform-cell">
                  <div className="searchform-table">
                    {/* <!-- Søkestreng --> */}
                    <div className="searchform-row">
                      <div className="searchform-inputfield searchform-cell">
                        <TextField
                          placeholder="Search"
                          id="searchText"
                          value={this.state.txtSearch}
                          onChange={(e, value) => {
                            this.txtSearch_OnChange(value);
                          }}
                          onKeyPress={key => this.onEnterKey(key)}
                          iconProps={{ iconName: "Search" }}
                        />
                      </div>
                    </div>
                    {/* <!-- Dato ---> */}
                    <div className="searchform-row">
                      <div className="searchform-table">
                        <div className="searchform-row">
                          <div
                            className="searchform-inputfield searchform-cell"
                            style={{ width: "50%" }}
                          >
                            <DatePicker
                              placeholder="From date"
                              id="dateFrom"
                              firstDayOfWeek={DayOfWeek.Monday}
                              // strings={DayPickerStrings}
                              value={this.state.txtDateFrom}
                              onSelectDate={value =>
                                this.txtDateFrom_OnChange(value)
                              }
                            />
                          </div>

                          <div
                            className="searchform-inputfield searchform-cell"
                            style={{ width: "50%" }}
                          >
                            <DatePicker
                              placeholder="To date"
                              id="dateTo"
                              firstDayOfWeek={DayOfWeek.Monday}
                              // strings={DayPickerStrings}
                              value={this.state.txtDateTo}
                              onSelectDate={this.txtDateTo_OnChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Postnummer --> */}
                    <div className="searchform-row">
                      <div className="searchform-table">
                        <div className="searchform-row">
                          <div
                            className="searchform-inputfield searchform-cell"
                            style={{ width: "50%" }}
                          >
                            <TextField
                              placeholder="Postal code from"
                              id="postalcodeFrom"
                              value={this.state.txtPostalFrom}
                              iconProps={{ iconName: "POI" }}
                              onChange={(e, value) =>
                                this.txtPostalCodeFrom_OnChange(value)
                              }
                            />
                          </div>

                          <div
                            className="searchform-inputfield searchform-cell"
                            style={{ width: "50%" }}
                          >
                            <TextField
                              placeholder="Postal code to"
                              id="postalcodeTo"
                              value={this.state.txtPostalTo}
                              iconProps={{ iconName: "POI" }}
                              onChange={(e, value) => {
                                this.txtPostalCodeTo_OnChange(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Status --> */}
                    <StatusSelection
                      selectedMainStatus={this.state.selectedMainStatus}
                      selectedSubStatus={this.state.selectedSubStatus}
                      statusConfig={this.props.data.statusConfig}
                      onMainStatusChange={(e, value) => {
                        this.OnMainStatusChange(value);
                      }}
                      onSubStatusChange={(e, value) => {
                        this.OnSubStatusChange(value);
                      }}
                    />

                    <div className="searchform-buttonfield">
                      <PrimaryButton
                        id="searchButton"
                        text={
                          this.props.data.isSearch ? "Searching..." : "Search"
                        }
                        disabled={this.props.data.isSearch}
                        onClick={this.btnSearchClicked}
                      />
                      <DefaultButton
                        id="clearButton"
                        text="Clear fields"
                        onClick={this.btnClearSearchClicked}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      getDepartmentConfig = () => {
        const { customers } = this.props.auth;
        let options = [];
        Object.keys(customers).forEach(function(key) {
          options.push(customers[key]);
        });

        let queryUrl = `${API}/api/v1.0/DepartmentConfig/Get/Customer/${
          options[0]
        }`;
        return fetch(queryUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${localStorage.getItem("token")}`
          }
        })
          .then(response => {
            if (response.ok && response.status !== 204) {
              // Request success
              return response.json().then(result => {
                this.setState({
                  customerDepartmentConfig: result,
                  txtDateFrom:
                    this.props.data.searchCriterias.dateFrom !== undefined
                      ? new Date(this.props.data.searchCriterias.dateFrom)
                      : new Date(
                          new Date().setDate(
                            new Date().getDate() - result.searchDaysBefore
                          )
                        ),
                  txtDateTo:
                    this.props.data.searchCriterias.dateTo !== undefined
                      ? new Date(this.props.data.searchCriterias.dateTo)
                      : new Date(
                          new Date().setDate(
                            new Date().getDate() + result.searchDaysAfter
                          )
                        ),
                  searchDaysAfter: result.searchDaysAfter,
                  searchDaysBefore: result.searchDaysBefore
                });
              });
            } else {
            }
          })
          .catch(error => {});
      };

      createStatusDrownDownOptions = () => {
        let options = [{ key: "-1", text: "All Statuses" }];

        for (let i = 0; i <= 10; i++) {
          if (
            this.props.data.statusConfig[`${i}.0`] !== undefined &&
            this.props.data.statusConfig[`${i}.0`] !== null
          ) {
            let item = this.props.data.statusConfig[`${i}.0`];
            options.push({ key: `${i}`, text: item.name });
          }
        }

        return options;
      };

      txtSearch_OnChange = value => {
        this.setState({ txtSearch: value });
      };

      txtPostalCodeFrom_OnChange = value => {
        if (value <= 0) {
          value = 0;
        }
        this.setState({ txtPostalFrom: value });
      };

      txtPostalCodeTo_OnChange = value => {
        if (value <= 0) {
          value = 0;
        }
        this.setState({ txtPostalTo: value });
      };

      txtDateFrom_OnChange = value => {
        if (value <= 0) {
          value = 0;
        }
        this.setState({ txtDateFrom: value });
      };

      txtDateTo_OnChange = value => {
        if (value <= 0) {
          value = 0;
        }
        this.setState({ txtDateTo: value });
      };

      OnMainStatusChange = value => {
        this.setState({
          ddMainStatus: parseInt(value.key, 10),
          selectedMainStatus: value.key
        });
      };

      OnSubStatusChange = value => {
        this.setState({
          ddSubStatus: parseInt(value.key, 10),
          selectedSubStatus: value.key
        });
      };

      btnSearchClicked = () => {
        let searchCriteria = {
          searchString: this.state.txtSearch,
          customerId: 0,
          postalCodeFrom:
            this.state.txtPostalFrom === "" ? 0 : this.state.txtPostalFrom,
          postalCodeTo:
            this.state.txtPostalTo === "" ? 0 : this.state.txtPostalTo,
          dateFrom: moment(this.state.txtDateFrom).format("YYYY-MM-DD HH:mm"),
          dateTo: moment(this.state.txtDateTo).format("YYYY-MM-DD HH:mm"),
          status: this.state.ddMainStatus,
          substatus: this.state.ddSubStatus,
          cwStatusId: this.state.ddMainStatus
        };
        this.props.functions.data.search(searchCriteria);
        if (this.props.onSearchCallback !== undefined)
          this.props.onSearchCallback();

        this.props.history.push("/");
      };

      btnClearSearchClicked = () => {
        this.props.functions.data.clearSearch();

        const initialState = {
          txtSearch: "",
          txtPostalFrom: "",
          txtPostalTo: "",
          txtDateFrom: new Date(
            new Date().setDate(
              new Date().getDate() - this.state.searchDaysBefore
            )
          ),
          txtDateTo: new Date(
            new Date().setDate(
              new Date().getDate() + this.state.searchDaysAfter
            )
          ),
          ddMainStatus: -1,
          selectedMainStatus: "-1",
          ddSubStatus: -1,
          selectedSubStatus: -1
        };

        this.setState(initialState);
      };

      onEnterKey = key => {
        switch (key.which) {
          case 13:
            this.btnSearchClicked();
            break;

          default:
            break;
        }
      };
    }
  )
);
