import store from "reduxstore/store";
// import { API } from "constants/variables";

export const UPDATE_APPLICATION_SETTING = (setting, value) => {
  return {
    type: "UPDATE_APPLICATION_SETTING",
    name: setting,
    value: value
  };
};

export const SHOW_LOGOUT_DIALOG = value => {
  return {
    type: "SHOW_LOGOUT_DIALOG",
    value: value
  };
};

export const SHOW_ORDER_DETAILS = (orderId, index) => {
  return {
    type: "SHOW_ORDER_DETAILS",
    orderId: orderId,
    selectedIndex: index
  };
};

export const HIDE_ORDER_DETAILS = () => {
  return {
    type: "HIDE_ORDER_DETAILS"
  };
};

export const SHOW_REGISTRATION_FORM = value => {
  return {
    type: "SHOW_REGISTRATION_FORM",
    value: value
  };
};

export const SET_REGISTRATION_STATUS = (value, orders, message) => {
  return {
    type: "SET_REGISTRATION_STATUS",
    value: value,
    orders: orders,
    message: message ? message : ""
  };
};
export const SET_REGISTRATION_INPROGRESS = value => {
  return {
    type: "SET_REGISTRATION_INPROGRESS",
    value: value
  };
};

export const updateApplicationSetting = (setting, value) => {
  store.dispatch(UPDATE_APPLICATION_SETTING(setting, value));
};

export const showLogoutDialog = value => {
  store.dispatch(SHOW_LOGOUT_DIALOG(value));
};

export const showOrderDetails = (orderId, index) => {
  store.dispatch(SHOW_ORDER_DETAILS(orderId, index));
};

export const hideOrderDetails = () => {
  store.dispatch(HIDE_ORDER_DETAILS());
};

export const toggleRegistrationForm = value => {
  if (value) {
    store.dispatch(SET_REGISTRATION_STATUS(0));
  }
  store.dispatch(SHOW_REGISTRATION_FORM(value));
};

export const setRegistrationFormStatus = value => {
  store.dispatch(SET_REGISTRATION_STATUS(value));
};
