import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { ContentContainer } from "containers/ContentContainer";
import { Version, Menu, LogoutDialog } from "components";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./MainContainer.css";
import "react-toastify/dist/ReactToastify.css";
const createBrowserHistory = require("history").createBrowserHistory;
const browserHistory = createBrowserHistory();
const mapStateToProps = (state) => {
  return state;
};

/*
  maincontainer container
*/

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class MainContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
      };
    }
    componentDidMount = () => {
      this.props.functions.data.clearSearch();
      if (this.props.auth.departmentId !== undefined) {
        this.props.functions.data.getStatusConfig(this.props.auth.departmentId);
      }
    };

    componentDidUpdate = (oldProps, oldState) => {
      if (oldProps.auth.departmentId === undefined && this.props.auth.departmentId !== undefined) {
        this.props.functions.data.getStatusConfig(this.props.auth.departmentId);
      }
    };

    render() {
      return (
        <div className="maincontainer-body animated fadeIn">
          <LogoutDialog />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            // style={{ width: "calc(100vw - 40px)" }}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
          <Router history={browserHistory}>
            <div className="maincontainer-container">
              <div className="maincontainer-menu">
                <Menu />
              </div>
              <div className="maincontainer-content">
                <ContentContainer />
              </div>
              <div className="maincontainer-footer">
                <Version />
              </div>
            </div>
          </Router>
        </div>
      );
    }
  }
);
