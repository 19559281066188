import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { OrderDetails, OrderList, OrderRegistration } from "components";
import { withRouter, Route } from "react-router-dom";
import "./ContentContainer.css";

const mapStateToProps = state => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class ContentContainer extends Component {
      constructor(props) {
        super(props);
        this.state = {
          hideInfo: true
        };
      }
      render() {
        return (
          <div className="content-container animated fadeIn">
            {/* <div className="content-column-full-width"> */}
            <Route exact path="/" component={OrderList} />
            <Route path="/details/:orderId" component={OrderDetails} />
            <Route path="/create/order" component={OrderRegistration} />
            {/* </div> */}
          </div>
        );
      }
    }
  )
);
