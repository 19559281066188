import React, { Component } from "react";
import { VERSION } from "version";
export default class Logo extends Component {
  render() {
    return (
      <div className="menu-logo-text">
        <svg
          className="menu-logo"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width={this.props.width}
          //   height={this.props.height / 10}
          viewBox="0 0 144 44"
          xmlSpace="preserve"
        >
          <path
            fill="#ffffff"
            d="M125.4,39.9c-0.5,0.9-1.9,2.6-4.3,2.6h-9.9c-2.4,0-3.8-1.7-4.2-2.5L93.7,12.8c-1.3-2.6-4.1-3.4-6.4-3.4
        L63,9.4c-2.2,0-3.5,1.4-3.5,3.5c0,2.2,1.5,3.5,3.6,3.5h16.7c7.1,0,13,4.3,13,13c0,8.7-5.9,13-13,13H58.3c-1.9,0-3.6-1.1-3.6-3.6
        v-4.7c0-0.8,0.5-1.2,1.2-1.2H75c2,0,3.6-1.2,3.6-3.5c0-2.3-1.6-3.5-3.6-3.5l-18,0c-3,0-5.7-0.8-7.7-2.1c-1,11.5-9.2,18.6-21.3,18.6
        c0,0-26.2,0-26.9,0c-0.7,0-1.2-0.4-1.2-1.2V17.7c0-0.8,0.5-1.2,1.2-1.2h9.5c2.5,0,3.6,1.6,3.6,3.5v11.8h0c0,0.8,0.5,1.2,1.2,1.2
        c2.5,0,8.2,0,8.2,0c6.5,0,11.8-3.9,11.8-11.8c0-7.9-5.3-11.8-11.8-11.8l-22.5,0C0.5,9.4,0,9,0,8.3V3.6C0,0.9,1.9,0,3.6,0l24.6,0
        C35.1,0,41.4,2.6,45,7.3C47,2.5,51.5,0,57.1,0h35.2c4.3,0,9.2,1.5,11.8,6.5l11.4,23.9c0.1,0.2,0.4,0.4,0.6,0.4
        c0.3,0,0.5-0.1,0.6-0.3c0,0,14.1-29.6,14.2-29.8C131,0.4,131.3,0,132,0c0.8,0,10.8,0,10.8,0c0.7,0,1.2,0.4,1.2,1.2
        c0,0.2,0,0.4-0.1,0.6L125.4,39.9z"
          />
        </svg>
        <div style={{ position: "fixed", top: -5, left: 90 }}>Customer Web</div>
        <div
          style={{
            position: "fixed",
            color: "rgba(255,255,255,0.6)",
            top: 13,
            left: 91,
            fontSize: "10px"
          }}
        >
          Version {VERSION}
          {/* <Version /> */}
        </div>
      </div>
    );
  }
}
