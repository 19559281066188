import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { MessageBar, MessageBarType } from "office-ui-fabric-react";
import { detect } from "detect-browser";
import "./NotSupported.css";

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(
  class NotSupported extends Component {
    render() {
      const browser = detect();
      const supportedBrowser = {
        name: undefined,
        version: undefined,
        complientVersion: undefined,
        complient: undefined
      };
      let version = browser.version.split(".")[0];

      switch (browser && browser.name) {
        case "chrome":
          supportedBrowser.name = "Google Chrome";
          supportedBrowser.version = browser.version;
          supportedBrowser.complientVersion = "57.x.x.x";
          if (version >= 57) {
            supportedBrowser.complient = 1;
          } else {
            supportedBrowser.complient = 0;
          }

          break;

        case "firefox":
          supportedBrowser.name = "Mozilla FireFox";
          supportedBrowser.version = browser.version;
          supportedBrowser.complientVersion = "52.x.x.x";
          if (version >= 52) {
            supportedBrowser.complient = 1;
          } else {
            supportedBrowser.complient = 0;
          }

          break;

        case "edge":
          supportedBrowser.name = "Microsoft Edge";
          supportedBrowser.version = browser.version;
          supportedBrowser.complientVersion = "52.x.x.x";
          if (version >= 16) {
            supportedBrowser.complient = 1;
          } else {
            supportedBrowser.complient = 0;
          }
          break;

        case "ie":
          supportedBrowser.name = "Microsoft Internet Explorer";
          supportedBrowser.version = browser.version;
          supportedBrowser.complientVersion = "none";
          supportedBrowser.complient = 0;
          break;

        default:
          supportedBrowser.name = browser.name;
          supportedBrowser.version = browser.version;
          supportedBrowser.complientVersion = "52.x.x.x";
          supportedBrowser.complient = 0;
      }

      if (supportedBrowser.complient === 0) {
        return (
          <div className="ns-full">
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={true}
              // onDismiss={() => console.log("test")}
              // dismissButtonAriaLabel="Close"
            >
              You are viewing this site with <b>{supportedBrowser.name}</b>{" "}
              version <b>{supportedBrowser.version}</b> and as a result this
              site will not be displayed properly.<br />For the best user
              experience, please upgrade to
              <a href="https://chrome.google.com">Google Chrome</a> or use
              latest version of
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                Microsoft Edge
              </a>
              or
              <a href="https://www.mozilla.org/en-US/firefox/new/">
                Mozilla FireFox
              </a>
            </MessageBar>
          </div>
        );
      } else if (supportedBrowser.complient === 2) {
        return (
          <div className="ns-full" style={{ zIndex: 100 }}>
            <MessageBar
              messageBarType={MessageBarType.warning}
              isMultiline={true}
              // onDismiss={() => console.log("test")}
              // dismissButtonAriaLabel="Close"
            >
              You are viewing this site with <b>{supportedBrowser.name}</b>{" "}
              version <b>{supportedBrowser.version}</b> and as a result this
              site will not be displayed properly.<br />For the best user
              experience, please upgrade to latest version of<a href="https://chrome.google.com">
                Google Chrome
              </a>,
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
                Microsoft Edge
              </a>{" "}
              or
              <a href="https://www.mozilla.org/en-US/firefox/new/">
                Mozilla FireFox
              </a>
            </MessageBar>
          </div>
        );
      } else {
        return null;
      }
    }
  }
);
