import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";

import {
  Dialog,
  DialogType,
  DialogFooter
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton
} from "office-ui-fabric-react/lib/Button";

import "./LogoutDialog.css";

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(
  class LogoutDialog extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false
      };
    }
    render() {
      return (
        <Dialog
          hidden={!this.props.application.showLogoutDialog}
          onDismiss={this.hideDialog}
          // isDarkOverlay={true}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Log out",
            subText: "Are you sure you want to log out?"
          }}
          modalProps={{
            titleAriaId: "myLabelId",
            subtitleAriaId: "mySubTextId",
            isBlocking: false,
            isDarkOverlay: true,
            containerClassName: "ms-dialogMainOverride"
          }}
        >
          {
            null /** You can also include null values as the result of conditionals */
          }
          <DialogFooter>
            <PrimaryButton onClick={this.logout} text="Yes" />
            <DefaultButton onClick={this.hideDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
      );
    }

    logout = () => {
      this.props.functions.application.showLogoutDialog(false);
      this.props.functions.auth.logoutUser();
    };
    hideDialog = () => {
      this.props.functions.application.showLogoutDialog(
        !this.props.application.showLogoutDialog
      );
    };
  }
);
