import { API } from "constants/variables";

export default class TransFleetObject {
  constructor(id) {
    this.id = id;
    this.values = undefined;
    this.apiQuery = ``;
  }

  Load = () => {
    let queryUrl = `${API}/api/v1.0/${this.apiQuery}`;
    return fetch(queryUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem("token")}`
      }
    })
      .then(response => {
        if (response.ok && response.status === 200) {
          return response.json().then(result => {
            return result;
          });
        } else {
          response.text().then(value => {
            return null;
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}
