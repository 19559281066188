import { API } from "constants/variables";
// import _ from "lodash";
import store from "reduxstore/store";
import moment from "moment";
import { SET_REGISTRATION_STATUS, SET_REGISTRATION_INPROGRESS } from "./ApplicationActions";

// Action
export const FETCH_SEARCH_RESULT = (result, searchCriterias) => {
  return {
    type: "FETCH_SEARCH_RESULT",
    orders: result,
    searchCriterias: searchCriterias,
    isSearching: false,
  };
};

export const FETCH_SEARCH_STARTED = () => {
  return {
    type: "FETCH_SEARCH_STARTED",
    isSearching: true,
  };
};

export const FETCH_SEARCH_CLEAR = () => {
  return {
    type: "FETCH_SEARCH_CLEAR",
    isSearching: false,
  };
};

// Action
export const FETCH_SEARCH_FILTER = (orders, query) => {
  return {
    type: "FETCH_SEARCH_FILTER",
    filteredOrders: orders,
    filterQuery: query,
  };
};

export const FETCH_SEARCH_FAILED = () => {
  return {
    type: "FETCH_SEARCH_FAILED",
    isSearching: false,
  };
};

export const FETCH_CUSTOMER_LOCATIONS = (result) => {
  return {
    type: "FETCH_CUSTOMER_LOCATIONS",
    customerLocations: result,
  };
};

export const FETCH_STATUS_CONFIG = (value) => {
  return {
    type: "FETCH_STATUS_CONFIG",
    statusConfig: value,
  };
};

export const FETCH_SEARCH_SET_CRITERIAS = (value) => {
  return {
    type: "FETCH_SEARCH_SET_CRITERIAS",
    statusConfig: value,
  };
};

export const search = (searchCriterias) => {
  store.dispatch(FETCH_SEARCH_CLEAR());
  store.dispatch(FETCH_SEARCH_STARTED());

  let queryUrl = `${API}/api/v1.0/Orders/Search/${searchCriterias.customerId}/${searchCriterias.postalCodeFrom}/${searchCriterias.postalCodeTo}/${searchCriterias.dateFrom}/${searchCriterias.dateTo}/${searchCriterias.cwStatusId}`;
  queryUrl = searchCriterias.searchString !== undefined ? queryUrl + `/${searchCriterias.searchString}` : queryUrl;
  return fetch(queryUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        // Request success
        response.json().then((result) => {
          // console.log(result);
          let orderItems = result.map((item, key) => {
            return {
              orderNo: item.orderId,
              orderId: item.reference,
              machineType: item.articleId,
              serialNo: item.serialNo,
              collectPlace: item.collectPlaceName,
              collectPlaceStreet: item.collectPlaceStreet,
              collectPlacePostalCode: item.collectPlacePostalCode,
              collectPlacePostalDistrict: item.collectPlacePostalDistrict,

              collectDate: moment(item.collectDate).format("DD-MM-YYYY HH:mm"),

              deliverPlace: item.deliverPlaceName,
              deliverPlaceStreet: item.deliverPlaceStreet,
              deliverPlacePostalCode: item.deliverPlacePostalCode,
              deliverPlacePostalDistrict: item.deliverPlacePostalDistrict,

              deliverDate: moment(item.deliverLatestDate).format("DD-MM-YYYY HH:mm"),
              status: item.orderExecuteStatus,
              subStatus: item.subStatus,
              stausComment: item.statusComment,
              publicComment: item.comment,
            };
          });
          store.dispatch(FETCH_SEARCH_RESULT(orderItems, searchCriterias));
        });
      } else {
        // Request failed
        response.text().then((value) => {
          store.dispatch(FETCH_SEARCH_FAILED());
        });
      }
    })
    .catch((error) => {
      store.dispatch(FETCH_SEARCH_FAILED());
    });
};

export const clearSearch = () => {
  store.dispatch(FETCH_SEARCH_CLEAR());
};

export const filterSearch = (searchText, orders) => {
  const text = searchText.toLowerCase();
  let items = text
    ? orders.filter(
        (i) =>
          (i.collectPlace !== null && i.collectPlace.toLowerCase().indexOf(text) > -1) ||
          (i.deliverPlace !== null && i.deliverPlace.toLowerCase().indexOf(text) > -1) ||
          (i.machineType !== null && i.machineType.toLowerCase().indexOf(text) > -1) ||
          (i.orderId !== null && i.orderId.toLowerCase().indexOf(text) > -1) ||
          (i.orderNo !== null &&
            i.orderNo
              .toString()
              .toLowerCase()
              .indexOf(text) > -1) ||
          (i.serialNo !== null && i.serialNo.toLowerCase().indexOf(text) > -1) ||
          (i.deliverPlacePostalCode !== null && i.deliverPlacePostalCode.toLowerCase().indexOf(text) > -1)
      )
    : orders;

  store.dispatch(FETCH_SEARCH_FILTER(items, searchText));
};

export const getCustomerLocations = (customerId) => {
  store.dispatch(FETCH_SEARCH_STARTED());

  let queryUrl = `${API}/api/v1.0/Customer/Get/CollectDeliverPlaces/${customerId}`;
  return fetch(queryUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        // Request success
        response.json().then((result) => {
          store.dispatch(FETCH_CUSTOMER_LOCATIONS(result));
        });
      } else {
        // Request failed
        response.text().then((value) => {
          store.dispatch(FETCH_SEARCH_FAILED());
        });
      }
    })
    .catch((error) => {
      store.dispatch(FETCH_SEARCH_FAILED());
    });
};

export const createOrder = (orderObject, fileObject) => {
  store.dispatch(SET_REGISTRATION_INPROGRESS(true));
  let queryUrl = `${API}/api/v1.0/Orders/CreateOrder`;
  return fetch(queryUrl, {
    method: "POST",
    body: JSON.stringify(orderObject),
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        // Request success
        response.json().then((result) => {
          if (fileObject !== undefined) {
            uploadFile(fileObject, result, (id) => {
              store.dispatch(SET_REGISTRATION_STATUS(id, result));
            });
          } else {
            store.dispatch(SET_REGISTRATION_STATUS(1, result));
          }
          store.dispatch(SET_REGISTRATION_INPROGRESS(false));
        });
      } else {
        // Request failed
        store.dispatch(SET_REGISTRATION_INPROGRESS(false));
        response.text().then((value) => {
          // console.log(value);
          store.dispatch(SET_REGISTRATION_STATUS(-3, null, value));
        });
      }
    })
    .catch((error) => {
      console.log(error);
      store.dispatch(SET_REGISTRATION_INPROGRESS(false));
      store.dispatch(SET_REGISTRATION_STATUS(-1, null));
    });
};

export const getStatusConfig = (customerId) => {
  let queryUrl = `${API}/api/v1.0/Orders/GetStatusDescription${customerId ? "/" + customerId : ""}`;
  return fetch(queryUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        // Request success
        response.json().then((result) => {
          // console.log(result);
          // let statusConfig = {};
          // result.forEach((item, key) => {
          //   statusConfig[`${item.id}.0`] = {
          //     id: item.id,
          //     name: item.name,
          //     color: item.color
          //   };
          //   item.subStatuses.forEach((substatus, id) => {
          //     statusConfig[`${item.id}.${substatus.id}`] = {
          //       id: substatus.id,
          //       name: `${item.name} (${substatus.name})`,
          //       color: item.color
          //     };
          //   });
          // });
          store.dispatch(FETCH_STATUS_CONFIG(result));
        });
      } else {
        // Request failed
        response.text().then((value) => {});
      }
    })
    .catch((error) => {});
};

export const uploadFile = (fileObject, arrayOrderIds, callback) => {
  var fileData = new FormData();

  fileObject.forEach((file) => {
    fileData.append(file[0].name, file[0]);
  });

  fetch(`${API}/api/v1.0/Orders/UploadOrderAttachments/${arrayOrderIds.join(",")}`, {
    method: "POST",
    body: fileData,
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: `bearer ${localStorage.getItem("token")}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        // Request success
        // response.json().then(result => {
        callback(2);
        // });
      } else {
        // Request failed
        callback(-2);
        response.text().then((value) => {});
      }
    })
    .catch((error) => {});
};
