import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import moment from "moment";

import {
  AppReducers,
  AuthenticationReducers,
  SettingsReducers,
  DataReducers
} from "reduxstore/reducers";

const reducers = combineReducers({
  application: AppReducers,
  auth: AuthenticationReducers,
  data: DataReducers,
  settings: SettingsReducers
});

// localStorage.removeItem("userSettings");

let userSavedSettings = JSON.parse(localStorage.getItem("userSettings"));
let defaultSettings = userSavedSettings
  ? userSavedSettings
  : {
      showUserSettingsDialog: true,
      showWelcomeBanner: true,
      isFirstTimeVisit: true,
      showInformationCard: true,
      selectedRegions: []
    };

export const initialState = {
  application: {
    registrationInProgress: false,
    showLogoutDialog: false,
    showRegistrationForm: false,
    orderDetails: {
      show: false,
      orderId: undefined,
      selectedIndex: -1
    },
    apiConfig: {}
  },
  auth: {
    authRequested: false,
    isAuthenticated: false,
    departmentId: undefined
  },
  data: {
    filteredOrders: [],
    customerLocations: [],
    statusConfig: []
  },
  settings: defaultSettings
};

let composeEnhancers = compose;
if (process.env.NODE_ENV !== "production") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk))(
  createStore
);

// const enhancer = compose(DevTools.instrument());
const store = createStoreWithMiddleware(reducers, initialState);
export default store;
