if (window.env.API === undefined) {
  window.env = {
    // This option can be retrieved in "src/index.js" with "window.env.API".
    API: "http://customer.dsv.transfleet.linkitall.com"
  };
}
// export let API = "https://customer.dsv.transfleet.no/"
export let API = window.env.API;
switch (window.location.hostname) {
  case "localhost":
    API = "http://localhost:8082";
    //API = "http://customer.dsv.transfleet.linkitall.com";
    break;
  case "customer.dsv.transfleet.linkitall.com":
    API = "http://customer.dsv.transfleet.linkitall.com";
    break;
  case "customerweb.dsv.test.transfleet.no":
    API = "http://customer.test.dsv.transfleet.no";
    break;

  // default:
  //   API = `https://${window.location.hostname}`;
  //   break; 
}

export let APPLICATION_NAME = "DSV CustomerWeb";

export let STATUS_COLOR = [];
STATUS_COLOR[99] = "236, 233, 216";
STATUS_COLOR[10] = "0, 51, 153, 204";
STATUS_COLOR[9] = "63, 156, 32";
STATUS_COLOR[7] = "159, 232, 159";
STATUS_COLOR[6] = "96, 227, 227";
STATUS_COLOR[5] = "192, 250, 192";
STATUS_COLOR[4] = "96, 227, 227";
STATUS_COLOR[3] = "250, 127, 127";
STATUS_COLOR[2] = "0, 255, 128, 0";
STATUS_COLOR[1] = "250, 250, 156";
STATUS_COLOR[0] = "255, 255, 255";
