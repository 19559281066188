import { Icon, TextField } from "office-ui-fabric-react";
import * as React from "react";

export function GoodsLine(props) {
  const goodsTemplate = {
    cll: "1",
    pll: "0",
    kg: "0",
    ldm: "0",
    cbm: "0",
    machine: "",
    serial: "",
    product: "",
  };

  const [errorStrings, setErrorStrings] = React.useState({
    cll: "CLL or PLL must have a value greater than zero",
    ldm: "CBM or LDM must have a value greater than zero",
    kg: "KG must have a value greater than zero",
    machine: "Product can not be empty",
  });
  const isNewItem = props.item === undefined ? true : false;
  const [goods, setGoods] = React.useState(
    !isNewItem ? Object.assign({}, props.item) : Object.assign({}, goodsTemplate)
  );

  /*
  Validate if the input is blank or numeric
  */
  const isNumeric = (value) => {
    return value === "" || /^([\d]+)([,.]?)([\d]{0,2})$/.test(value);
  };

  /*
  Set the value of the property (item)
  and update the goods state
  */
  const updateValue = (item, value) => {
    goods[item] = value;
    if (!isNewItem) {
      props.onUpdateItem(goods);
      const isValid = validateValues();
      props.onValidateGoodsLine(isValid);
    }
    setGoods(Object.assign({}, goods));
  };

  const getFloatValue = (value) => {
    if (value !== "" || value !== undefined) {
      if (typeof value === "number") {
        return parseFloat(value);
      } else {
        return parseFloat(value.replace(",", "."));
      }
    }
    return 0;
  };

  /*
  Validate the values for the goodsline
  based on various criterias
  */
  const validateValues = () => {
    let isValid = true;
    if (
      goods["cll"].length === 0 ||
      goods["pll"].length === 0 ||
      (getFloatValue(goods["cll"]) <= 0 && getFloatValue(goods["pll"]) <= 0)
    ) {
      errorStrings.cll = "CLL or PLL must have a value greater than zero";
      isValid = false;
    } else {
      errorStrings.cll = "";
    }

    if (
      goods["cbm"].length === 0 ||
      goods["ldm"].length === 0 ||
      (getFloatValue(goods["cbm"]) <= 0 && getFloatValue(goods["ldm"]) <= 0)
    ) {
      errorStrings.ldm = "CBM or LDM must have a value greater than zero";
      isValid = false;
    } else {
      errorStrings.ldm = "";
    }

    if (goods["kg"].length === 0 || getFloatValue(goods["kg"]) <= 0) {
      errorStrings.kg = "KG must have a value greater than zero";
      isValid = false;
    } else {
      errorStrings.kg = "";
    }

    if (goods["machine"] === undefined || goods["machine"].length <= 0) {
      errorStrings.machine = "Product can not be empty";
      isValid = false;
    } else {
      errorStrings.machine = "";
    }

    setErrorStrings(Object.assign({}, errorStrings));

    return isValid;
  };

  React.useEffect(() => {
    validateValues();
  }, []);

  return (
    <div>
      <div className="registration-goods-form">
        <div className="registration-goods-machineserial">
          <div>
            <TextField
              label="Product"
              value={goods.machine}
              onChange={(e, value) => {
                updateValue("machine", value);
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  // updateValue("machine", "");
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="Serial Number"
              value={goods.serial}
              onChange={(e, value) => {
                updateValue("serial", value);
              }}
            />
          </div>
        </div>
        <div className="registration-goods-form-dimensions">
          <div>
            <TextField
              label="CLL"
              value={goods.cll}
              onChange={(e, value) => {
                if (isNumeric(value)) {
                  updateValue("cll", value);
                }
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  updateValue("cll", 0);
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="PLL"
              value={goods.pll}
              onChange={(e, value) => {
                if (isNumeric(value)) {
                  updateValue("pll", value);
                }
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  updateValue("pll", 0);
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="KG"
              value={goods.kg}
              onChange={(e, value) => {
                if (isNumeric(value)) {
                  updateValue("kg", value);
                }
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  updateValue("kg", 0);
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="Ldm"
              value={goods.ldm}
              onChange={(e, value) => {
                if (isNumeric(value)) {
                  updateValue("ldm", value);
                }
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  updateValue("ldm", 0);
                }
              }}
            />
          </div>
          <div>
            <TextField
              label="Cbm"
              value={goods.cbm}
              onChange={(e, value) => {
                if (isNumeric(value)) {
                  updateValue("cbm", value);
                }
              }}
              onBlur={(e, value) => {
                if (e.target.value === "") {
                  updateValue("cbm", 0);
                }
              }}
            />
          </div>
        </div>
        <div className="registration-goods-buttongroup">
          {isNewItem}
          {isNewItem ? (
            <Icon
              title="Add goods line"
              iconName="CircleAddition"
              onClick={() => {
                if (validateValues()) {
                  props.onAddNewItem(goods);
                  setGoods(Object.assign({}, goodsTemplate));
                }
              }}
            />
          ) : (
            <Icon
              iconName="Blocked2"
              onClick={() => {
                props.onRemoveItem(Object.assign({}, goods));
              }}
            />
          )}
        </div>
      </div>
      <div style={{ color: "#a80000", fontSize: "12px" }}>
        {false && (
          <div>
            <b>Incomplete goods information</b>
          </div>
        )}
        {Object.keys(errorStrings).map((item, ix) => {
          return <div key={ix}>{errorStrings[item]}</div>;
        })}
      </div>
    </div>
  );
}
