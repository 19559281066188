import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Version } from "components";
import "./LoginContainer.css";

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class LoginContainer extends Component {
    constructor(props) {
      super(props);
      this.state = {
        username: "undefined",
        password: "undefined",
        viewPassword: false
      };
    }

    componentWillReceiveProps = nextProps => {
      if (nextProps.auth.authFailed === true) {
        setTimeout(() => {
          this.props.functions.auth.resetAuthFailure();
        }, 1000);
      }
    };

    render() {
      let animationError = this.props.auth.authFailed ? "shake animated" : null;
      let animationFadeIn = this.props.auth.authFailed
        ? "flipInX animated"
        : null;

      let passwordIcon = this.state.viewPassword ? "view" : "permissions";
      let passwordType = this.state.viewPassword ? "text" : "password";
      return (
        <div className="login-body animated fadeIn">
          <div className={`login-container ${animationError}`}>
            <div className="login-shadow">
              <div className="login-logo">DSV CustomerWeb</div>
              <div className="login-content-container">
                <div className="login-username-row">
                  <TextField
                    id="username-input"
                    iconProps={{ iconName: "Accounts" }}
                    onChange={(e, input) => {
                      this.setState({ username: input });
                    }}
                  />
                </div>
                <div className="login-password-row">
                  <TextField
                    id="password-input"
                    type={passwordType}
                    iconProps={{
                      iconName: passwordIcon,
                      className: "login-permission-icon",
                      onMouseOver: () => this.viewPassword(true),
                      onMouseLeave: () => this.viewPassword(false)
                    }}
                    onChange={(e, input) => this.setState({ password: input })}
                    onKeyUp={key => this._txtPasswordOnKeyPress(key)}
                  />
                </div>
                <div className={`login-validation-row ${animationFadeIn}`}>
                  {this.props.auth.message}
                </div>
                <div className="login-button-row">
                  <DefaultButton
                    className="login-button-fullwidth"
                    primary={true}
                    disabled={false}
                    checked={false}
                    text="Logg inn"
                    onClick={this._btnLoginOnClick}
                  />
                  {/* <Button
                    className="pt-fill pt-intent-primary"
                    iconName="log-in"
                    loading={this.props.auth.authRequested}
                    onClick={this._btnLoginOnClick}
                  >
                    Logg inn
                  </Button> */}
                  {/* <div className="login-register">Ny bruker?</div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="login-footer">
            <Version />
          </div>
        </div>
      );
    }
    _btnLoginOnClick = () => {
      this._authenticateUser();
    };

    _txtPasswordOnKeyPress(key) {
      if (key.keyCode === 13) {
        this._authenticateUser();
      }
    }

    _authenticateUser = () => {
      this.props.functions.auth.loginUser(
        this.state.username,
        this.state.password
      );
    };

    viewPassword = value => {
      this.setState({ viewPassword: value });
    };
  }
);
