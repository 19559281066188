import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";

import LoginContainer from "./containers/LoginContainer/LoginContainer";
import MainContainer from "./containers/MainContainer/MainContainer";

import { initializeIcons } from "@uifabric/icons";
import { ErrorHandler } from "components";

initializeIcons(undefined, { disableWarnings: true });

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class Main extends Component {
    constructor(props) {
      super(props);

      this.state = {
        validationInterval: undefined,
        error: null
      };
    }

    componentDidMount = () => {
      this.props.functions.auth.validateExistingToken();

      clearInterval(this.state.validationInterval);
      const validationInterval = setInterval(() => {
        this.props.functions.auth.validateExistingToken();
      }, 60000 * 10); // 10 min

      this.setState({ validationInterval });
    };

    componentDidCatch = (error, event) => {
      this.setState({ error: { error, event } });
    };

    render() {
      if (this.state.error) {
        return (
          <ErrorHandler
            showErrorMessage={this.state.error ? true : false}
            error={this.state.error}
            onDismiss={() => {
              this.setState({ error: null });
              window.location.reload();
            }}
          />
        );
      }

      if (!this.props.auth.isAuthenticated) {
        return (
          <div>
            <LoginContainer />
          </div>
        );
      }

      return <MainContainer />;
    }
  }
);
