import moment from "moment";
import store from "reduxstore/store";
import jwt_decode from "jwt-decode";
import { API } from "constants/variables";

// Action
export const AUTHENTICATION_REQUEST = () => {
  return {
    type: "AUTHENTICATION_REQUEST",
    authRequested: true
  };
};

export const AUTHENTICATION_SUCCESS = () => {
  return {
    type: "AUTHENTICATION_SUCCESS",
    isAuthenticated: true,
    authRequested: false,
    message: ""
  };
};

export const AUTHENTICATION_FAILED = message => {
  return {
    type: "AUTHENTICATION_FAILED",
    isAuthenticated: false,
    authRequested: false,
    authFailed: true,
    message: message
  };
};

export const AUTHENTICATION_RESET = () => {
  return {
    type: "AUTHENTICATION_RESET",
    isAuthenticated: false
  };
};

export const AUTHENTICATION_VALIDATE = (isValid, message = "") => {
  return {
    type: "AUTHENTICATION_VALIDATE",
    isAuthenticated: isValid,
    message: message
  };
};

export const AUTHENTICATION_USER = user => {
  return { type: "AUTHENTICATION_USER", user: user };
};

export const AUTHENTICATION_CUSTOMERS = customers => {
  return { type: "AUTHENTICATION_CUSTOMERS", customers: customers };
};

export const AUTHENTICATION_CUSTOMER_DEPARTMENTID = value => {
  return {
    type: "AUTHENTICATION_CUSTOMER_DEPARTMENTID",
    departmentId: value
  };
};

export const loginUser = (username, password) => {
  store.dispatch(AUTHENTICATION_REQUEST());

  // let authPayload = {
  //   username: username,
  //   password: password
  // };

  let authPayload = {
    username: username,
    password: password
  };

  return fetch(`${API}/api/v1.0/Authentication`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(authPayload)
  })
    .then(response => {
      if (response.ok) {
        // Request success
        response.json().then(result => {
          if (result.access_token) {
            // Authentication success
            var token = jwt_decode(result.access_token);
            var customers = JSON.parse(token.customers);
            var user = JSON.parse(token.user);
            // Validate if token is not expired
            if (new Date(1000 * token.exp) > new Date()) {
              localStorage.setItem("token", result.access_token);
              getDepartmentId(customers);
              store.dispatch(AUTHENTICATION_CUSTOMERS(customers));
              store.dispatch(AUTHENTICATION_USER(user));
              store.dispatch(AUTHENTICATION_SUCCESS());
            }
          } else {
            // Authentication failed
            store.dispatch(AUTHENTICATION_FAILED(result.message));
          }
        });
      } else {
        if (response.status === 401) {
          store.dispatch(AUTHENTICATION_FAILED("Wrong username or password"));
        }
      }
    })
    .catch(error => {
      console.log(error);
      store.dispatch(AUTHENTICATION_FAILED("No connection to TransFleet"));
    });
};

export const logoutUser = () => {
  localStorage.removeItem("token");
  store.dispatch(AUTHENTICATION_RESET());
};

export const getDepartmentId = customers => {
  const tmpCustomers = [];
  if (customers !== undefined) {
    Object.keys(customers).forEach((key, ix) => {
      tmpCustomers.push(customers[key]);
    });
  }

  let queryUrl = `${API}/api/v1.0/Customer/Get/DepartmentId/${tmpCustomers[0]}`;
  return fetch(queryUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem("token")}`
    }
  })
    .then(response => {
      if (response.ok) {
        // Request success
        response.text().then(result => {
          store.dispatch(AUTHENTICATION_CUSTOMER_DEPARTMENTID(result));
        });
      } else {
        // Request failed
        response.text().then(value => {});
      }
    })
    .catch(error => {});
};

export const resetAuthFailure = () => {
  store.dispatch(AUTHENTICATION_RESET());
};

export const validateExistingToken = () => {
  // Validate token
  let token = localStorage.getItem("token");
  if (token) {
    if (isValid(token)) {
      let customers = JSON.parse(jwt_decode(token).customers);
      let user = JSON.parse(jwt_decode(token).user);
      store.dispatch(AUTHENTICATION_USER(user));

      if (customers) {
        getDepartmentId(customers);
        store.dispatch(AUTHENTICATION_CUSTOMERS(customers));
      }
      store.dispatch(AUTHENTICATION_VALIDATE(true));
      // let expireTime = tokenExpreIn(token);
    } else {
      store.dispatch(AUTHENTICATION_VALIDATE(false, "Session has expired"));
    }
  } else {
    store.dispatch(AUTHENTICATION_VALIDATE(false));
  }
};

function isValid(token) {
  let _token = jwt_decode(token);

  if (!token) {
    return false;
  }
  // console.log(`Token expiredate: ${tokenExpireDate(token)}`);
  return new Date(1000 * _token.exp) > new Date();
}

export function tokenExpireDate(token) {
  let _token = jwt_decode(token);
  if (!_token) {
    return null;
  }
  let expireTime = moment.unix(_token.exp);
  return expireTime;
}

export function tokenExpreIn(token) {
  let _token = jwt_decode(token);
  if (!_token) {
    return null;
  }
  let current = moment();
  let expireTime = moment.unix(_token.exp);
  let duration = moment.duration(expireTime.diff(current));
  return duration;
}
