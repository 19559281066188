import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "./index.css";
import Main from "./main";
import Notsupported from "./containers/NotSupported/NotSupported";

import { Store } from "reduxstore";

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(
  <Provider store={Store}>
    <div>
      <Notsupported />
      <Main />
    </div>
  </Provider>,
  document.getElementById("root")
);

// import registerServiceWorker from "./registerServiceWorker";
// registerServiceWorker();
