import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";

import {
  Dialog,
  DialogType,
  DialogFooter
} from "office-ui-fabric-react/lib/Dialog";
import {
  PrimaryButton,
  DefaultButton
} from "office-ui-fabric-react/lib/Button";

import "./CustomDialog.css";

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(
  class CustomDialog extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false
      };
    }
    render() {
      return (
        <Dialog
          hidden={this.props.hidden}
          onDismiss={this.props.onDismiss}
          dialogContentProps={{
            type: DialogType.normal,
            title: this.props.title,
            subText: this.props.subText
          }}
          modalProps={{
            // className: "animated fadeIn",
            isBlocking: false,
            isDarkOverlay: true,
            ...this.props.modalProps
          }}
        >
          {this.props.children}
          <DialogFooter>
            {this.props.ConfirmButtonText && (
              <PrimaryButton
                onClick={this.props.onClickConfirmButton}
                text={this.props.ConfirmButtonText}
              />
            )}
            {this.props.DismissButtonText && (
              <DefaultButton
                onClick={this.props.onClickDismiss}
                text={this.props.DismissButtonText}
              />
            )}
          </DialogFooter>
        </Dialog>
      );
    }
  }
);
