import React, { Component } from "react";
import "./Card.css";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: false,
      shouldUnmount: false
    };
  }

  componentWillUpdate = (nextProps, nextState) => {
    if (
      (nextProps.isHidden && !this.props.isHidden) ||
      (nextState.isHidden && !this.state.isHidden)
    ) {
      setTimeout(() => {
        this.setState({ shouldUnmount: true });
      }, 1000);
    }
  };
  componentWillUnmount = () => {
    // ryddopp hvis noe skal ryddes opp
  };

  render() {
    const {
      onClose,
      closable,
      title,
      description,
      hideDivider,
      isHidden
    } = this.props;
    let animation = isHidden || this.state.isHidden ? "animated fadeOut" : null;
    if (this.state.shouldUnmount) {
      return null;
    }

    return (
      <div className={`card-body ${animation}`}>
        {(onClose || closable) && (
          <div className="card-closebutton" onClick={this._onClose}>
            <i className="ms-Icon ms-Icon--ChromeClose" aria-hidden="true" />
          </div>
        )}
        <div className="card-container">
          <div className="card-header ms-fontWeight-semibold ms-fontSize-l">
            {title}
          </div>

          {description && (
            <div className="card-description ms-fontSize-sPlus">
              {description}
            </div>
          )}
          {(description || title) &&
            !hideDivider && <div className="card-divider" />}
          <div className="card-content ms-fontSize-mPlus">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  _onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      this.setState({ isHidden: true });
    }
  };
}
