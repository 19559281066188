import {
  loginUser,
  logoutUser,
  validateExistingToken,
  resetAuthFailure,
  tokenExpreIn,
  getDepartmentId
} from "./actions/AuthenticationActions";

import {
  search,
  clearSearch,
  filterSearch,
  getCustomerLocations,
  createOrder,
  uploadFile,
  getStatusConfig
} from "./actions/DataActions";

import { saveUserSettings, updateUserSetting } from "./actions/SettingsActions";
import {
  updateApplicationSetting,
  showLogoutDialog,
  showOrderDetails,
  hideOrderDetails,
  toggleRegistrationForm,
  setRegistrationFormStatus
} from "./actions/ApplicationActions";

export const mapDispatchToProps = () => {
  return {
    functions: {
      application: {
        updateApplicationSetting: (setting, value) => {
          updateApplicationSetting(setting, value);
        },

        showLogoutDialog: value => {
          showLogoutDialog(value);
        },

        showOrderDetails: (orderId, index) => {
          showOrderDetails(orderId, index);
        },

        hideOrderDetails: () => {
          hideOrderDetails();
        },

        toggleRegistrationForm: value => {
          toggleRegistrationForm(value);
        },

        clearRegistrationStatus: () => {
          setRegistrationFormStatus(0);
        }
      },
      auth: {
        loginUser: (username, password) => {
          loginUser(username, password);
        },

        logoutUser: () => {
          logoutUser();
        },

        resetAuthFailure: () => {
          resetAuthFailure();
        },

        validateExistingToken: () => {
          validateExistingToken();
        },
        getTokenExpireTime: () => {
          tokenExpreIn(localStorage.getItem("token"));
        },
        getDepartmentId: customerId => {
          getDepartmentId(customerId);
        }
      },
      data: {
        search: searchCriteria => {
          search(searchCriteria);
        },

        clearSearch: () => {
          clearSearch();
        },

        filterSearch: (value, orders) => {
          filterSearch(value, orders);
        },
        getCustomerLocations: customerId => {
          getCustomerLocations(customerId);
        },
        getStatusConfig: customerId => {
          getStatusConfig(customerId);
        },

        createOrder: (orderObject, fileObject) => {
          createOrder(orderObject, fileObject);
        },

        uploadFile: (fileObject, arrayOrderIds, callback) => {
          uploadFile(fileObject, arrayOrderIds, callback);
        }
      },

      settings: {
        saveUserSettings: () => {
          saveUserSettings();
        },
        updateUserSetting: (setting, value) => {
          updateUserSetting(setting, value);
        }
      }
    }
  };
};
