import moment from "moment";
import * as React from "react";
import { STATUS_COLOR } from "constants/variables";

export const StatusHistory = props => {
  const isTransport =
    parseInt(props.orderInfo.id, 10) !==
    parseInt(props.statusHistory.bookingId, 10);

  const orderId = props.orderInfo.id;

  const statusInfo = {
    orderId: orderId,
    orderStatus: props.statusHistory.status
  };
  if (props.transportStatus) {
    return (
      <div className="orderdetails-column">
        <TransportStatusTable
          isTransport={isTransport}
          status={statusInfo}
          statusConfig={props.statusConfig}
        />
      </div>
    );
  }
  return (
    <div className="orderdetails-column">
      <BookingStatusTable
        isTransport={isTransport}
        status={props.statusHistory.bookingStatus}
        statusConfig={props.statusConfig}
      />
    </div>
  );
};

const TransportStatusTable = props => {
  const status = props.status.orderStatus.reverse();

  return (
    <div className={`orderdetails-status-booking`}>
      <div style={{ marginLeft: "5px" }}>
        <div className="orderdetails-status-table">
          {status &&
            status.map((item, key) => {
              const orderStatus = item.status;
              let color = STATUS_COLOR[orderStatus.value];
              let statusIx = props.statusConfig.findIndex((statusItem, ix) => {
                if (statusItem.subStatus === -1) {
                  return statusItem.id === orderStatus.value;
                } else {
                  return (
                    statusItem.id === orderStatus.value &&
                    statusItem.subStatus === orderStatus.subValue
                  );
                }
              });

              return (
                <div
                  key={key}
                  style={{
                    background: key % 2 ? "rgba(0,0,0,0.02)" : "",
                    marginBottom: "20px"
                  }}
                >
                  <table>
                    <tbody>
                      <tr>
                        <th width="50%">Order status</th>
                        <th width="50%">Time</th>
                      </tr>

                      <tr>
                        <td>
                          <div
                            className="orderdetails-status-table-icon"
                            style={{
                              float: "left",
                              backgroundColor: "rgba(" + color + ",0.6)"
                            }}
                          ></div>
                          {props.statusConfig[statusIx].description}
                        </td>
                        <td>
                          {moment(orderStatus.timeStatus).format(
                            "DD-MM-YYYY HH:mm "
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table>
                    <tbody>
                      {/* <tr>
                        <th width="150px">Collect Place</th>
                        <th width="150px">Delivery Place</th>
                      </tr> */}

                      <tr>
                        <td width="100px" style={{ fontWeight: "bold" }}>
                          Collect place
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          {item.collectLocation.name}
                        </td>
                      </tr>
                      <tr>
                        <td width="50px" style={{ fontWeight: "bold" }}>
                          Delivery place
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          {item.deliveryLocation.name}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const BookingStatusTable = props => {
  const status = props.status;
  return (
    <div className="orderdetails-column">
      <b>Status history</b>
      <div className={`orderdetails-status-booking`}>
        <div style={{ marginLeft: "5px" }}>
          <div className="orderdetails-status-table">
            <table>
              <tbody>
                <tr>
                  <th width="250px">Order status</th>
                  <th width="150px">Time</th>
                  <th width="150px">Terminal</th>
                  <th width="150px">Comment</th>
                </tr>
                {status !== undefined &&
                  status.map((item, key) => {
                    let color = STATUS_COLOR[item.value];
                    let statusIx = props.statusConfig.findIndex(
                      (statusItem, ix) => {
                        if (statusItem.subStatus === -1) {
                          return statusItem.id === item.value;
                        } else {
                          return (
                            statusItem.id === item.value &&
                            statusItem.subStatus === item.subValue
                          );
                        }
                      }
                    );

                    if (key < 5 && statusIx > -1) {
                      return (
                        <tr key={key}>
                          <td style={{ verticalAlign: "top", width: "20px" }}>
                            <div
                              className="orderdetails-status-table-icon"
                              style={{
                                float: "left",
                                backgroundColor: "rgba(" + color + ",0.6)"
                              }}
                            >
                              &nbsp;
                            </div>
                            {props.statusConfig[statusIx].description}
                          </td>
                          <td style={{ verticalAlign: "top" }}>
                            {moment(item.timeStatus).format("HH:mm DD.MM.YYYY")}
                          </td>
                          <td>{item.terminal}</td>
                          <td>{item.comment}</td>
                        </tr>
                      );
                    }
                    return null;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
