import {
  DefaultButton,
  PrimaryButton
} from "office-ui-fabric-react/lib/Button";
import {
  Dialog,
  DialogFooter,
  DialogType
} from "office-ui-fabric-react/lib/Dialog";
import { Icon } from "office-ui-fabric-react/lib/Icon";
import React, { Component } from "react";
import "./ErrorHandler.css";

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.refTextarea = React.createRef();
  }
  render() {
    return (
      <Dialog
        hidden={!this.props.showErrorMessage}
        onDismiss={this.props.onDismiss}
        dialogContentProps={{
          type: DialogType.normal,
          title: (
            <div>
              <Icon
                iconName="error"
                style={{ fontSize: "25px", marginRight: "10px" }}
              />
              Something went wrong :(
            </div>
          ),
          subText: this.props.subText
        }}
        maxWidth={"75%"}
        modalProps={{
          // className: "animated fadeIn",
          isBlocking: true,
          isDarkOverlay: true,
          style: {
            width: "500px"
          },
          ...this.props.modalProps
        }}
      >
        <div>
          <div
            style={{ marginBottom: "15px", wordWrap: "wrap", width: "450px" }}
          >
            Please send the following cryptic message to us with a short
            description of what happend so we can inform our developers.
          </div>
          <div style={{ marginBottom: "15px" }}>
            <b>When you click the OK button the page will be reloaded.</b>
          </div>
          <div>
            <textarea
              className="transparent-selection"
              readOnly={true}
              ref={this.refTextarea}
              cols={100}
              rows={25}
            >
              {this.props.error.error.toString() +
                " " +
                this.props.error.event.componentStack}
            </textarea>
          </div>
        </div>
        <DialogFooter>
          <DefaultButton
            onClick={this.copyToClipboard}
            text={"Copy to clipboard"}
          />
          <PrimaryButton onClick={this.props.onDismiss} text={"OK"} />
        </DialogFooter>
      </Dialog>
    );
  }

  copyToClipboard = () => {
    if (this.refTextarea.current !== null) {
      this.refTextarea.current.select();
      document.execCommand("copy");
    }
  };
}
