"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _locuscard = require("./components/locuscard");
Object.defineProperty(exports, "LocusCard", {
  enumerable: true,
  get: function get() {
    return _interopRequireDefault(_locuscard).default;
  }
});
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}