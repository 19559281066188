import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { CustomDialog } from "components";

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class OrderSignatureDialog extends Component {
    constructor(props) {
      super(props);

      this.state = {
        showConfirmMessage: false
      };
    }
    render() {
      return (
        <CustomDialog
          title={"Signature"}
          hidden={!this.props.show}
          ConfirmButtonText="Ok"
          onClickConfirmButton={() => this.successDialogSupress()}
          onDismiss={() => this.props.onDismiss()}
          modalProps={{
            isBlocking: false
          }}
        >
          <div>
            <img src={`data:image/jpg;base64,${this.props.signature}`} alt="" />
          </div>
        </CustomDialog>
      );
    }

    orderIds = orderIds => {
      if (orderIds !== undefined) {
        let orderIdElement = orderIds.map((item, id) => {
          return <div key={id}>{item}</div>;
        });

        return (
          <div>
            The following order(s) have been created in TransFleet.
            <div style={{ marginTop: "5px" }}>{orderIdElement}</div>
          </div>
        );
      }
    };

    successDialogSupress = () => {
      this.props.functions.application.clearRegistrationStatus();
      this.props.functions.application.toggleRegistrationForm(false);
      this.props.onDismiss();
    };
  }
);
