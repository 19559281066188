import { CustomDialog, TimePicker } from "components";
import { API, APPLICATION_NAME } from "constants/variables";
import { LocusCard } from "locuscard";
import { findIndex } from "lodash";
import moment from "moment";
import {
  Dropdown,
  Icon,
  PrimaryButton,
  // DefaultButton,
  TextField,
  colGroupProperties,
} from "office-ui-fabric-react";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import OrderClass from "../../classes/OrderClass";
import "./OrderDetails.css";
import OrderSignatureDialog from "./OrderSignatureDialog";
import { StatusHistory } from "./StatusHistory/StatusHistory";

const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class OrderDetails extends Component {
    constructor(props) {
      super(props);
      const { orderId } = this.props.match.params;
      this.orderInfo = new OrderClass(orderId);

      this.state = {
        order: undefined,
        statusHistory: undefined,
        orderAttachments: undefined,
        orderSignatures: undefined,
        showSignature: false,
        showDFPod: false,
        showOrderNotFoundDialog: false,
        signature: undefined,
        showGoodsInformationDetails: true,
        showArticleInformationDetails: true,
        statusVisibility: {
          booking: false,
          transports: [],
        },
        customerDepartmentConfig: {},
      };
    }

    componentDidMount = () => {
      const { customers } = this.props.auth;
      let options = [];
      Object.keys(customers).forEach(function(key) {
        options.push(customers[key]);
      });
      this.getDepartmentConfig(options[0]);

      this.orderInfo.Load().then((result) => {
        if (result !== undefined) {
          console.log(result);
          result.articles = this.convertToOrderArticle(result);
          this.setState({ order: result });

          this.orderInfo.GetStatusHistory().then((result) => {
            this.setState({ statusHistory: result });
          });

          this.orderInfo.GetOrderAttachmentsList().then((result) => {
            this.setState({ orderAttachments: result });
          });

          this.orderInfo.GetOrderSignatures().then((result) => {
            this.setState({ orderSignatures: result });
          });

          this.checkIfDFPodEnabled(this.state.order.orderHead.customerId);
        } else {
          this.setState({ showOrderNotFoundDialog: true });
        }
      });
    };

    componentDidUpdate = (prevProps, prevState) => {
      const { orderId } = this.props.match.params;
      const { prevOrderId } = prevProps.match.params;

      this.orderInfo = new OrderClass(orderId);
      if (prevOrderId !== undefined && orderId !== prevOrderId) {
        this.orderInfo.Load().then((result) => {
          result.articles = this.convertToOrderArticle(result);
          this.setState({ order: result });
        });
      }
    };

    render() {
      const { orderId } = this.props.match.params;
      if (this.state.order === undefined || this.state.statusHistory === undefined) {
        return (
          <CustomDialog
            title={`Could not display order`}
            hidden={!this.state.showOrderNotFoundDialog}
            ConfirmButtonText="Ok"
            onClickConfirmButton={() => {
              this.setState({ showOrderNotFoundDialog: false });
              this.props.history.push("/");
            }}
            onDismiss={() => {
              this.setState({ showOrderNotFoundDialog: false });
              this.props.history.push("/");
            }}
            modalProps={{
              isBlocking: false,
            }}
          >
            Order {orderId} could not be displayed.
            <p>
              <b>Reason: </b>
              Either the order does not exists or you do not have access to display it.
            </p>
          </CustomDialog>
        );
      }
      if (!this.props.hidden) {
        return (
          <div className="animated fadeIn">
            <OrderSignatureDialog
              show={this.state.showSignature}
              signature={this.state.signature}
              onDismiss={() => {
                this.setState({ showSignature: false });
              }}
            />

            <Helmet>
              <title>{`${APPLICATION_NAME} - Details - ${orderId}`}</title>
            </Helmet>

            <LocusCard
              title={`Order details ${this.state.order.orderHead.id}`}
              hideDivider
              onClose={this.onClose}
              hidden={this.props.hidden}
              closeable
            >
              <div>
                {/* {this.createSaveGroup()} */}
                <div className="orderdetails-main-container">
                  <div>
                    <div className="orderdetails-container">
                      {/* {this.createCustomerSection()} */}

                      {/* Collect Place */}
                      {this.createCollectPlaceSection()}

                      {/* Deliver Place */}
                      {this.createDeliverPlaceSection()}
                    </div>
                    {/* </div>
                  <div> */}
                    <div className="orderdetails-container">
                      {/* Goods */}
                      {this.createGoodsSection()}
                      {/* Counters */}
                      {this.createCounterSection()}
                    </div>

                    <div className="orderdetails-container">
                      {/* Status */}
                      {/* {this.createStatusHistory()} */}
                      <StatusHistory
                        statusHistory={this.state.statusHistory}
                        statusConfig={this.props.data.statusConfig}
                        statusVisibility={this.state.statusVisibility}
                        orderInfo={this.orderInfo}
                        transportStatus={this.state.customerDepartmentConfig.transportStatus}
                      />

                      {/* Reference */}
                      {this.createReferneceSection()}

                      {/* Attachments */}
                      {this.createOrderAttachments()}
                    </div>

                    <div className="orderdetails-container" />
                  </div>
                </div>
                {this.createSaveGroup()}
              </div>
            </LocusCard>
          </div>
        );
      } else {
        return null;
      }
    }

    getDepartmentConfig = (customerId) => {
      let queryUrl = `${API}/api/v1.0/DepartmentConfig/Get/Customer/${customerId}`;
      return fetch(queryUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => {
          if (response.ok && response.status !== 204) {
            // Request success
            return response.json().then((result) => {
              this.setState({
                customerDepartmentConfig: result,
              });
            });
          } else {
          }
        })
        .catch((error) => {});
    };

    createCustomerSection = () => {
      return (
        <div className="orderdetails-column">
          <b>Customer</b>
          <Dropdown
            label="Select customer"
            id="Basicdrop1"
            defaultSelectedKey={this.state.selectedCustomer}
            options={this.getCustomerNoAsOptions()}
            onChange={(e, value) => this.customerOnChange(value)}
            componentRef={this._basicDropdown}
          />
        </div>
      );
    };

    createCollectPlaceSection = () => {
      return (
        <div className="orderdetails-column">
          <b>Collect Place</b>
          <div className="orderdetails-collectplace-frame">
            {/* Left */}
            <div>
              <TextField label="Name" defaultValue={this.state.order.orderHead.collectLocation.name} readOnly />
              <TextField label="Address" defaultValue={this.state.order.orderHead.collectLocation.street} readOnly />
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Contact"
                    defaultValue={this.state.order.orderHead.collectLocation.contact}
                    readOnly
                  />
                </div>
                <div className="orderdetails-section-item">
                  <TextField label="Phone" defaultValue={this.state.order.orderHead.collectLocation.phone} readOnly />
                </div>
              </div>
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="ZIP-code"
                    defaultValue={this.state.order.orderHead.collectLocation.postalCode}
                    readOnly
                  />
                </div>
                <div className="orderdetails-section-item">
                  <TextField
                    label="City"
                    defaultValue={this.state.order.orderHead.collectLocation.postalDistrict}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* Right */}
            <div style={{ marginLeft: "10px" }}>
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Collect date"
                    defaultValue={moment(this.state.order.orderHead.collectLocation.serviceTimeEarliest).format(
                      "DD-MM-YYYY"
                    )}
                    readOnly
                  />
                </div>
                <div>
                  <TimePicker
                    value={moment(this.state.order.orderHead.collectLocation.serviceTimeEarliest).format("HH:mm")}
                    readOnly
                  />
                </div>
              </div>

              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Collect date latest"
                    defaultValue={moment(this.state.order.orderHead.collectLocation.serviceTimeLatest).format(
                      "DD-MM-YYYY"
                    )}
                    readOnly
                  />
                </div>
                <div>
                  <TimePicker
                    value={moment(this.state.order.orderHead.collectLocation.serviceTimeLatest).format("HH:mm")}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    createDeliverPlaceSection = () => {
      const ix = findIndex(this.state.order.properties.innerList, (o) => {
        return o.id === 1200;
      });
      const techPhone = this.state.order.properties.innerList[ix].value;

      return (
        <div className="orderdetails-column">
          <b>Delivery Place</b>
          <div className="orderdetails-collectplace-frame">
            {/* Left */}
            <div>
              <TextField label="Name" defaultValue={this.state.order.orderHead.deliveryLocation.name} readOnly />
              <TextField label="Address" defaultValue={this.state.order.orderHead.deliveryLocation.street} readOnly />
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Contact"
                    defaultValue={this.state.order.orderHead.deliveryLocation.contact}
                    readOnly
                  />
                </div>
                <div className="orderdetails-section-item">
                  <TextField label="Phone" defaultValue={this.state.order.orderHead.deliveryLocation.phone} readOnly />
                </div>
              </div>
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="ZIP-code"
                    defaultValue={this.state.order.orderHead.deliveryLocation.postalCode}
                    readOnly
                  />
                </div>
                <div className="orderdetails-section-item">
                  <TextField
                    label="City"
                    defaultValue={this.state.order.orderHead.deliveryLocation.postalDistrict}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* Right */}
            <div style={{ marginLeft: "10px" }}>
              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Deliver date"
                    defaultValue={moment(this.state.order.orderHead.deliveryLocation.serviceTimeEarliest).format(
                      "DD-MM-YYYY"
                    )}
                    readOnly
                  />
                </div>
                <div>
                  <TimePicker
                    value={moment(this.state.order.orderHead.deliveryLocation.serviceTimeEarliest).format("HH:mm")}
                    readOnly
                  />
                </div>
              </div>

              <div className="orderdetails-section-form">
                <div className="orderdetails-section-item">
                  <TextField
                    label="Deliver date latest"
                    defaultValue={moment(this.state.order.orderHead.deliveryLocation.serviceTimeLatest).format(
                      "DD-MM-YYYY"
                    )}
                    readOnly
                  />
                </div>
                <div>
                  <TimePicker
                    value={moment(this.state.order.orderHead.deliveryLocation.serviceTimeLatest).format("HH:mm")}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {techPhone && (
              <div className="orderdetails-section-item">
                <TextField label="SMS preadvise phone" defaultValue={techPhone} readOnly />
              </div>
            )}
          </div>
        </div>
      );
    };

    createGoodsSection = () => {
      let transportType = "Unkown";
      if (this.state.order.orderLines && this.state.order.orderLines.length > 0) {
        switch (this.state.order.orderLines[0].productCode) {
          default:
          case "D":
            transportType = "Delivery / Install";
            break;
          case "R":
            transportType = "Return";
            break;
          case "I":
            transportType = "Internal move";
            break;
          case "E":
            transportType = "External move";
            break;
        }
      }
      return (
        <div
          className="orderdetails-column"
          onClick={() => {
            const showGoodsInformationDetails = !this.state.showGoodsInformationDetails;
            this.setState({
              showGoodsInformationDetails,
            });
          }}
        >
          {this.state.showGoodsInformationDetails === true ? (
            <Icon iconName="TriangleSolidDown12" className="orderdetails-expand-collapse" />
          ) : (
            <Icon iconName="TriangleSolidRight12" className="orderdetails-expand-collapse" />
          )}
          <b>Goods Information</b>
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            <b>Transport type :</b> {transportType}
          </div>
          {this.state.showGoodsInformationDetails && <div>{this.createGoodsRegistraionElement()}</div>}
        </div>
      );
    };

    createCounterSection = () => {
      return (
        <div
          className="orderdetails-column"
          onClick={() => {
            const showArticleInformationDetails = !this.state.showArticleInformationDetails;
            this.setState({
              showArticleInformationDetails,
            });
          }}
        >
          {this.state.showArticleInformationDetails === true ? (
            <Icon iconName="TriangleSolidDown12" className="orderdetails-expand-collapse" />
          ) : (
            <Icon iconName="TriangleSolidRight12" className="orderdetails-expand-collapse" />
          )}
          <b>Article Information</b>
          {this.state.showArticleInformationDetails && <div>{this.createCounterRegistraionElement()}</div>}
        </div>
      );
    };

    createReferneceSection = () => {
      return (
        <div className="orderdetails-column">
          <b>Reference</b>
          <div className="orderdetails-section-form">
            <div>
              <div style={{ width: "150px" }}>
                <TextField label="Order Initials" defaultValue={this.state.order.orderHead.pod} readOnly />
              </div>
              <div>
                <TextField label="Order ref" defaultValue={this.state.order.orderHead.references[1]} readOnly />
              </div>
            </div>
          </div>
          <div className="orderdetails-section-form">
            <div>
              <div>
                <TextField
                  label={`Order comment`}
                  multiline
                  rows={5}
                  readOnly={true}
                  defaultValue={
                    this.state.order.comments[1] !== null && this.state.order.comments[1].comment
                      ? this.state.order.comments[1].comment
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    };

    createCounterRegistraionElement = () => {
      const { orderLines, articles } = this.state.order;

      return (
        <table width="100%">
          <tbody style={{ textAlign: "left" }}>
            <tr>
              <th>Product Type</th>
              <th>Serial Number</th>
              <th>Black White</th>
              <th>Color</th>
              <th>Total</th>
              <th>Counter 1</th>
              <th>Counter 2</th>
              <th>Counter 3</th>
              <th>Article No.</th>
            </tr>

            {orderLines !== undefined &&
              orderLines[0] !== undefined &&
              articles.map((article, key) => {
                const counters = [];
                for (var articleKey in article) {
                  if (articleKey.includes("Tellerverk")) {
                    counters.push(article[articleKey]);
                  }
                }
                return (
                  <tr key={key}>
                    <td>{article.ArticleId}</td>
                    <td>{article.SerialNo}</td>
                    <td>{counters[0]}</td>
                    <td>{counters[1]}</td>
                    <td>{counters[2]}</td>
                    <td>{counters[3]}</td>
                    <td>{counters[4]}</td>
                    <td>{counters[5]}</td>
                    <td>{article["Article No."]}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      );
    };

    createGoodsRegistraionElement = () => {
      const { orderLines, articles } = this.state.order;

      return (
        <table width="100%">
          <tbody style={{ textAlign: "left" }}>
            <tr>
              <th>Product Type</th>
              <th>Serial Number</th>
              <th style={{ textAlign: "center" }}>CLL</th>
              <th style={{ textAlign: "center" }}>PLL</th>
              <th style={{ textAlign: "center" }}>KG</th>
              <th style={{ textAlign: "center" }}>Ldm</th>
              <th style={{ textAlign: "center" }}>Cbm</th>
            </tr>

            {orderLines !== undefined &&
              orderLines[0] !== undefined &&
              articles.map((article, key) => {
                const cargoLineIndex = findIndex(orderLines, (i) => {
                  return parseInt(i.index, 10) === parseInt(article.CargoLineIndex, 10);
                });

                if (orderLines[cargoLineIndex] !== undefined) {
                  return (
                    <tr key={key}>
                      <td>{article.ArticleId}</td>
                      <td>{article.SerialNo}</td>
                      <td style={{ textAlign: "center" }}>{orderLines[cargoLineIndex].quantity}</td>
                      <td style={{ textAlign: "center" }}>{orderLines[cargoLineIndex].pallets}</td>
                      <td style={{ textAlign: "center" }}>{orderLines[cargoLineIndex].weight}</td>
                      <td style={{ textAlign: "center" }}>{orderLines[cargoLineIndex].loadingMeters}</td>
                      <td style={{ textAlign: "center" }}>{orderLines[cargoLineIndex].volume}</td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
          </tbody>
        </table>
      );
    };

    createOrderAttachments = () => {
      /*
       * Denne ser seriøst dritt ut, må endres til èn tabell
       * der typen dokument beskrives i egen kolonne.
       */
      const { orderAttachments, orderSignatures } = this.state;
      // console.log(this.state);
      const consignmentNo = this.state.order.orderHead.consignmentNo;

      // switch (this.state.order.orderHead.customerId){
      //   case 123:
      //   showDFPod = true;
      //   case 123:
      //   showDFPod = true;
      // }

      let items = [];
      let dfPods = undefined;
      let signatures = [];

      if (orderAttachments !== undefined) {
        items = orderAttachments.map((item, id) => {
          return (
            <tr key={id}>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>{moment(item.created).format("HH:mm DD.MM.YYYY")}</td>
              <td style={{ textAlign: "center" }}>
                <Icon
                  iconName="DownloadDocument"
                  className="pointer"
                  onClick={() => this.donwloadFile(item.blobId, item.name)}
                />
              </td>
            </tr>
          );
        });
      }
      if (consignmentNo !== undefined && consignmentNo !== null && consignmentNo !== "" && this.state.showDFPod) {
        console.log(consignmentNo);
        dfPods = (
          <tr>
            <td>Danske Fragtmænd POD</td>
            <td />
            <td style={{ textAlign: "center" }}>
              <Icon
                iconName="DownloadDocument"
                className="pointer "
                onClick={() => this.donwloadPod(consignmentNo, `POD_${consignmentNo}.pdf`)}
              />
            </td>
          </tr>
        );
      }
      if (orderSignatures !== undefined) {
        signatures = orderSignatures.map((item, id) => {
          return (
            <tr key={id}>
              <td>{item.initials}</td>
              <td>{moment(item.created).format("HH:mm DD.MM.YYYY")}</td>
              <td style={{ textAlign: "center" }}>
                <Icon
                  iconName="DownloadDocument"
                  className="pointer"
                  onClick={() => this.downloadSignature(this.orderInfo.id, item.signatureImage)}
                />
              </td>
            </tr>
          );
        });
      }

      return (
        <div className="orderdetails-column">
          <b>Documentation</b>
          <div className="orderdetails-section-form">
            <div style={{ marginTop: "5px" }}>
              <div className="orderdetails-status-table">
                <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                  <b>Reports</b>
                  <div style={{ marginLeft: "20px" }}>
                    <table className="">
                      <tbody>
                        <tr>
                          <th width="35%">Type</th>
                          <th width="55%">Description</th>
                          <th>Download</th>
                        </tr>
                        <tr>
                          <td>Freight Note</td>
                          <td />
                          <td style={{ textAlign: "center" }}>
                            <Icon
                              iconName="DownloadDocument"
                              className="pointer"
                              onClick={() => this.downloadReport(this.orderInfo.id)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Parcel Label</td>
                          <td />
                          <td style={{ textAlign: "center" }}>
                            <Icon
                              iconName="DownloadDocument"
                              className="pointer"
                              onClick={() => this.downloadParcelLabel(this.orderInfo.id)}
                            />
                          </td>
                        </tr>
                        {dfPods}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ marginTop: "25px", marginBottom: "5px" }}>
                  <b>Signatures</b>
                  <div style={{ marginLeft: "20px" }}>
                    <table className="">
                      <tbody>
                        <tr>
                          <th width="70%">Initials</th>
                          <th width="30%">Time</th>
                          <th>View</th>
                        </tr>
                        {signatures}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ marginTop: "25px", marginBottom: "5px" }}>
                  <b>Attachments / Pictures</b>
                  <div style={{ marginLeft: "20px" }}>
                    <table className="">
                      <tbody>
                        <tr>
                          <th width="35%">Filename</th>
                          <th width="40%">Description</th>
                          <th width="15%">Time</th>
                          <th>Download</th>
                        </tr>
                        {items}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

    createSaveGroup = () => {
      return (
        <div className="orderdetails-save-horisontal">
          <div />
          <PrimaryButton text="Close" onClick={this.onClose} />
        </div>
      );
    };

    convertToOrderArticle = (order) => {
      let orderArticles = [];
      let orderArticleObject = {
        OrderId: undefined,
        OrderArticleId: undefined,
        ArticleId: undefined,
        ArticleLine: undefined,
        CargoLineIndex: undefined,
        ParcelNumber: undefined,
        SerialNo: undefined,
        Quantity: undefined,
        Reference_1: undefined,
        Reference_2: undefined,
        Reference_3: undefined,
        Reference_4: undefined,
        Reference_5: undefined,
        Reference_6: undefined,
        Reference_7: undefined,
        Reference_8: undefined,
        Reference_9: undefined,
        Reference_10: undefined,
        Reference_11: undefined,
        Reference_12: undefined,
        Reference_13: undefined,
        Reference_14: undefined,
        Reference_15: undefined,
      };

      if (order !== undefined && order.articles !== undefined) {
        order.articles.forEach((article, key) => {
          let orderArticle = Object.assign({}, orderArticleObject);
          article.properties.forEach((prop, propKey) => {
            orderArticle[prop.displayName] = prop.propertyValue;
          });
          orderArticles.push(orderArticle);
        });
      }
      return orderArticles;
    };

    onClose = () => {
      this.props.history.goBack();
    };

    donwloadFile = (blobId, filename) => {
      let url = `${API}/api/v1/Orders/GetOrderAttachment/${blobId}`;
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `bearer  ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
    };

    donwloadPod = (consignmentNo, filename) => {
      let url = `${API}/api/v1.0/Fragtdk/Get/Pod/${consignmentNo}`;
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `bearer  ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
    };

    downloadReport = (orderId) => {
      let url = `${API}/api/v1/Orders/GetDefaultOrderReport/${orderId}`;
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `bearer  ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${orderId}.pdf`;
          a.click();
        });
    };

    downloadParcelLabel = async (orderId) => {
      let url = `${API}/api/v1/Orders/GetDefaultParcelReport/${orderId}`;
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `bearer  ${localStorage.getItem("token")}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `${orderId}.pdf`;
          a.click();
        });
    };

    checkIfDFPodEnabled = (customerId) => {
      let url = `${API}/api/v1/Customer/Get/${customerId}`;
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: `bearer  ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        if (response.ok) {
          // Request success
          response.json().then((result) => {
            this.setState({ showDFPod: result });
          });
        }
      });
    };

    downloadSignature = (orderId, blob) => {
      this.setState({ signature: blob, showSignature: true });
    };
  }
);
