export const AuthenticationReducers = (state = {}, action) => {
  switch (action.type) {
    case "AUTHENTICATION_REQUEST":
      return { ...state, authRequested: action.authRequested };
    case "AUTHENTICATION_SUCCESS":
      return {
        ...state,
        authRequested: action.authRequested,
        isAuthenticated: action.isAuthenticated,
        authFailed: false,
        message: action.message
      };
    case "AUTHENTICATION_FAILED":
      return {
        ...state,
        authRequested: action.authRequested,
        isAuthenticated: false,
        authFailed: true,
        message: action.message
      };
    case "AUTHENTICATION_RESET":
      return { ...state, isAuthenticated: false, authFailed: false };
    case "AUTHENTICATION_VALIDATE":
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
        message: action.message
      };
    case "AUTHENTICATION_USER":
      return { ...state, user: action.user };
    case "AUTHENTICATION_CUSTOMERS":
      return { ...state, customers: action.customers };
    case "AUTHENTICATION_PREAUTH":
      return {
        ...state,
        authRequested: false,
        companies: action.companies,
        isPreAuth: action.isPreAuth,
        message: undefined
      };

    case "AUTHENTICATION_CUSTOMER_DEPARTMENTID":
      return {
        ...state,
        departmentId: action.departmentId
      };

    default:
      return state;
  }
};
