import store from "reduxstore/store";

export const SAVE_USER_SETTINGS = () => {
  return {
    type: "SAVE_USER_SETTINGS"
  };
};

export const UPDATE_USER_SETTING = (setting, value) => {
  return {
    type: "UPDATE_USER_SETTING",
    name: setting,
    value: value
  };
};

export const saveUserSettings = () => {
  store.dispatch(SAVE_USER_SETTINGS());
};

export const updateUserSetting = (setting, value) => {
  store.dispatch(UPDATE_USER_SETTING(setting, value));
  store.dispatch(SAVE_USER_SETTINGS());
};
