export const AppReducers = (state = {}, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return { ...state, isLoading: action.isLoading };

    case "UPDATE_APPLICATION_SETTING":
      return {
        ...state,
        [action.name]: action.value
      };

    case "SHOW_LOGOUT_DIALOG":
      return {
        ...state,
        showLogoutDialog: action.value
      };

    case "SHOW_ORDER_DETAILS":
      return {
        ...state,
        orderDetails: {
          show: true,
          orderId: action.orderId,
          selectedIndex: action.selectedIndex
        }
      };

    case "HIDE_ORDER_DETAILS":
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          show: false
        }
      };
    case "SHOW_REGISTRATION_FORM":
      return {
        ...state,
        showRegistrationForm: action.value
      };

    case "SET_REGISTRATION_STATUS":
      return {
        ...state,
        registrationFormStatus: action.value,
        newOrderIds: action.orders,
        message: action.message ? action.message : ""
      };

    case "SET_REGISTRATION_INPROGRESS":
      return {
        ...state,
        registrationInProgress: action.value
      };

    default:
      return state;
  }
};
