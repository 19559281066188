let newState = {};
export const DataReducers = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_SEARCH_RESULT":
      newState = {
        ...state,
        orders: action.orders,
        filteredOrders: action.orders,
        searchCriterias: action.searchCriterias,
        isSearch: false
      };
      return newState;

    case "FETCH_CUSTOMER_LOCATIONS":
      return {
        ...state,
        customerLocations: action.customerLocations,
        isSearch: false
      };

    case "FETCH_STATUS_CONFIG":
      newState = {
        ...state,
        statusConfig: action.statusConfig
      };
      return newState;

    case "FETCH_SEARCH_CLEAR":
      return {
        ...state,
        orders: [],
        filteredOrders: [],
        searchCriterias: {
          searchString: "",
          postalCodeFrom: "",
          postalCodeTo: "",
          dateFrom: undefined,
          dateTo: undefined,
          status: -1,
          substatus: -1
        },
        isSearch: false
      };

    case "FETCH_SEARCH_FILTER":
      return {
        ...state,
        filteredOrders: action.filteredOrders,
        filterQuery: action.filterQuery
      };

    case "FETCH_SEARCH_STARTED":
      return {
        ...state,
        isSearch: true
      };

    case "FETCH_SEARCH_FAILED":
      return {
        ...state,
        isSearch: false
      };

    default:
      return state;
  }
};
