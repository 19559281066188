import { API, APPLICATION_NAME } from "constants/variables";
import { LocusCard } from "locuscard";
import _ from "lodash";
import moment from "moment";
import { DefaultButton, Dropdown, Icon, PrimaryButton, TextField, Toggle } from "office-ui-fabric-react";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { mapDispatchToProps } from "reduxstore";
import { isArray } from "util";
import OrderRegistraionConfirmation from "./OrderRegistraionConfirmation";
import "./OrderRegistration.css";
import { GoodsLine } from "./Segments/GoodsLine";
import { Place } from "./Segments/Place";

const mapStateToProps = (state) => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class OrderRegistration extends PureComponent {
      constructor(props) {
        super(props);

        const { customers } = this.props.auth;
        let options = [];
        Object.keys(customers).forEach(function(key) {
          options.push(customers[key]);
        });
        this.initialState = {
          goodsRegistrationElements: [],
          goodsValues: [
            {
              key: 0,
              cll: "1",
              pll: "0",
              kg: "0",
              ldm: "0",
              cbm: "0",
              product: "",
              serial: "",
            },
          ],
          selectedCustomer: options[0],
          manualCollectPlace: {
            name: undefined,
            address: undefined,
            postalCode: undefined,
            postalDistrict: undefined,
            contact: undefined,
            phone: undefined,
          },
          manualDeliverPlace: {
            name: undefined,
            address: undefined,
            postalCode: undefined,
            postalDistrict: undefined,
            contact: undefined,
            phone: undefined,
          },
          selectedCollectPlace: undefined,
          selectedDeliverPlace: undefined,

          collectPlaceContact: undefined,
          collectPlacePhone: undefined,

          deliverPlaceContact: undefined,
          deliverPlacePhone: undefined,

          collectDate: undefined,
          collectTime: "00:00",
          collectTimeLatest: "00:00",
          collectDateLatest: undefined,
          deliverDate: undefined,
          deliverTime: "00:00",
          deliverTimeLatest: "00:00",
          deliverDateLatest: undefined,
          splitOrder: false,
          showConfirmMessage: false,
          attachmentOk: false,
          attachments: undefined,
          transportType: "D",
          customerDepartmentConfig: undefined,
          error: null,
          preadvicePhone: "",
          // errorMessage: {
          //   hasErrors: false,
          //   goods: [{}]
          // },
          invalidGoodsLines: [false],
          isTimeValid: [],
        };

        this.state = this.initialState;
        this.getDepartmentConfig(options[0]);
      }

      componentDidMount = () => {
        if (this.state.selectedCustomer !== undefined) {
          this.props.functions.data.getCustomerLocations(this.state.selectedCustomer);
        }
        // this.createGoodsRegistraionElement();
      };

      componentDidUpdate = (prevProps, prevState) => {
        if (
          this.props.application.registrationFormStatus !== prevProps.application.registrationFormStatus &&
          this.props.application.registrationFormStatus !== 0
        ) {
          this.setState({
            showConfirmMessage: true,
          });
        }
      };

      componentDidCatch = (error, event) => {
        alert(error);
      };

      getDepartmentConfig = (customerId) => {
        let queryUrl = `${API}/api/v1.0/DepartmentConfig/Get/Customer/${customerId}`;
        return fetch(queryUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((response) => {
            if (response.ok && response.status !== 204) {
              // Request success
              return response.json().then((result) => {
                this.setState({
                  customerDepartmentConfig: result,
                  splitOrder: result.oneMachinePrOrder,
                });
              });
            } else {
            }
          })
          .catch((error) => {});
      };

      render() {
        return (
          <div className="animated fadeIn">
            <OrderRegistraionConfirmation
              show={this.state.showConfirmMessage}
              onDismiss={() => {
                this.setState({
                  showConfirmMessage: false,
                });
                this.props.history.push("/");
              }}
            />
            <Helmet>
              <title>{`${APPLICATION_NAME} - Registration `}</title>
            </Helmet>
            <LocusCard
              title="Create new order"
              hideDivider
              onClose={() => {
                this.props.history.push("/");
                this.props.functions.application.toggleRegistrationForm(false);
              }}
              closeable
            >
              {/* {this.createSaveGroup()} */}
              <div className="registration-main-container">
                <div>
                  <div className="registration-container">
                    {/* {this.createCustomerSection()} */}

                    {/* Collect Place */}
                    <Place
                      typeOfPlace={"collect"}
                      title={"collect"}
                      selectedCustomer={this.state.selectedCustomer}
                      selectedPlace={this.state.selectedCollectPlace}
                      manualPlace={this.state.manualCollectPlace}
                      date={this.state.collectDate}
                      dateLatest={this.state.collectDateLatest}
                      customerLocations={this.getCustomerLocationsAsOptions()}
                      onDropdownUpdate={this.collectPlaceOnChanged}
                      onUpdatePlace={(place) => {
                        this.setState({
                          selectedCollectPlace: undefined,
                          manualCollectPlace: place,
                        });
                      }}
                      onUpdate={(field, value) => {
                        this.setState({ ...[field], [field]: value });
                      }}
                      onTimeValidation={(field, valid) => {
                        const isTimeValid = Object.assign([], this.state.isTimeValid);
                        isTimeValid[field] = valid;

                        this.setState({ isTimeValid });
                      }}
                    />

                    {/* Deliver Place */}
                    <Place
                      typeOfPlace={"deliver"}
                      title={"delivery"}
                      showPreadvicePhone={true}
                      preadvicePhoneValue={this.state.preadvicePhone}
                      onPreadvicePhoneChange={this.onPreadvicePhoneChange}
                      selectedCustomer={this.state.selectedCustomer}
                      selectedPlace={this.state.selectedDeliverPlace}
                      manualPlace={this.state.manualDeliverPlace}
                      date={this.state.deliverDate}
                      dateLatest={this.state.deliverDateLatest}
                      customerLocations={this.getCustomerLocationsAsOptions()}
                      onDropdownUpdate={this.deliverPlaceOnChanged}
                      onUpdatePlace={(place) => {
                        this.setState({
                          selectedDeliverPlace: undefined,
                          manualDeliverPlace: place,
                        });
                      }}
                      onUpdate={(field, value) => {
                        this.setState({ ...[field], [field]: value });
                      }}
                      onTimeValidation={(field, valid) => {
                        const isTimeValid = Object.assign([], this.state.isTimeValid);
                        isTimeValid[field] = valid;
                        this.setState({ isTimeValid });
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="registration-container">
                    {/* TransportType */}
                    {this.createTransportTypeSection()}

                    {/* Goods */}
                    {this.createGoodsSection()}

                    {/* Reference */}
                    {this.createReferneceSection()}

                    {/* Order Attachments */}
                    {this.createOrderAttachmentSection()}
                  </div>
                </div>
              </div>
              <div className="registration-save-horisontal">
                <PrimaryButton
                  text="Create"
                  onClick={() => {
                    if (this.isFormValid()) {
                      this.saveForm();
                    }
                  }}
                  disabled={
                    this.state.goodsValues < 1 ||
                    !this.isDatesValid() ||
                    !this.isGoodsLineValid() ||
                    this.isTimeInvalid() ||
                    this.props.application.registrationInProgress
                  }
                />
                <DefaultButton text="Cancel" onClick={this.createSaveForm_CancleClick} />
              </div>
            </LocusCard>
          </div>
        );
      }

      createCustomerSection = () => {
        return (
          <div className="registration-column">
            <b>Customer</b>
            <Dropdown
              label="Select customer"
              id="Basicdrop1"
              defaultSelectedKey={this.state.selectedCustomer}
              options={this.getCustomerNoAsOptions()}
              onChange={(e, value) => {
                this.customerOnChange(value);
              }}
              componentRef={this._basicDropdown}
            />
          </div>
        );
      };

      createTransportTypeSection = () => {
        return (
          <div className="registration-column">
            <b>Transport Type</b>
            <div className="registration-goods-settings">
              <div>
                <Dropdown
                  options={[
                    { key: "D", text: "Delivery/Install" },
                    { key: "R", text: "Return" },
                    { key: "I", text: "Internal move" },
                    { key: "E", text: "External move" },
                  ]}
                  selectedKey={this.state.transportType}
                  onChange={this.setTransportType}
                />
              </div>
            </div>
          </div>
        );
      };

      createGoodsSection = () => {
        return (
          <div className="registration-column">
            <b>Goods</b>
            <div className="registration-goods-settings">
              <div>
                <Toggle
                  label={`Save each product as its own order`}
                  checked={this.state.splitOrder}
                  onText="Yes"
                  offText="No"
                  onChanged={(value) => this.setState({ splitOrder: value })}
                />
              </div>
            </div>

            <div>{this.createGoodsRegistraionElement()}</div>
            {/* <div className="registration-button">
              <PrimaryButton
                text="Add product"
                onClick={this.createGoodsRegistraionElement}
              />
            </div> */}
          </div>
        );
      };

      createReferneceSection = () => {
        return (
          <div className="registration-column">
            <b>Reference</b>
            <div className="registration-section-form">
              <div>
                <div style={{ width: "150px" }}>
                  <TextField label="Order Initials" onChange={(e, value) => this.setState({ initials: value })} />
                </div>
                <div>
                  <TextField label="Order ref" onChange={(e, value) => this.setState({ orderReference: value })} />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    label={`Order comment ${
                      this.state.commentField ? " (" + (500 - this.state.commentField.length) + " characters left)" : ""
                    }`}
                    multiline={true}
                    rows={5}
                    placeholder="This field is optional, and can max contain 500 characters"
                    onChange={(e, value) => {
                      this.setState({ commentField: value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      };

      createGoodsRegistraionElement = () => {
        let { goodsValues } = this.state;

        return (
          <div>
            <PrimaryButton
              text="Add product"
              onClick={() => {
                const goodsValues = Object.assign([], this.state.goodsValues);
                const invalidGoodsLines = Object.assign([], this.state.invalidGoodsLines);
                invalidGoodsLines.unshift(false);

                const item = {
                  cll: "1",
                  pll: "0",
                  kg: "0",
                  ldm: "0",
                  cbm: "0",
                  product: "",
                  serial: "",
                };

                item.key = `${goodsValues.length}${new Date().getTime().toFixed()}`;

                // Update and assign
                goodsValues.unshift(Object.assign({}, item));
                this.setState({ goodsValues, invalidGoodsLines });
              }}
            />

            {goodsValues.map((item, key) => {
              return (
                <GoodsLine
                  key={item.key}
                  item={item}
                  onRemoveItem={(value) => {
                    const goodsValues = Object.assign([], this.state.goodsValues);

                    const invalidGoodsLines = Object.assign([], this.state.invalidGoodsLines);

                    invalidGoodsLines.splice(key, 1);
                    goodsValues.splice(key, 1);

                    this.setState({ goodsValues, invalidGoodsLines });
                  }}
                  onUpdateItem={(value) => {
                    const goodsValues = Object.assign([], this.state.goodsValues);
                    goodsValues[key] = value;
                    this.setState({ goodsValues });
                  }}
                  onValidateGoodsLine={(valid) => {
                    this.validateGoodsLine(key, valid);
                  }}
                />
              );
            })}
          </div>
        );
      };

      createOrderAttachmentSection = () => {
        return (
          <div className="registration-column">
            <b>Attachments</b>
            <div className="registration-section-form">
              <div>
                <div style={{ marginLeft: "10px" }}>
                  {this.state.attachmentsList && this.state.attachmentsList.length > 0 && (
                    <table width="100%">
                      <tbody>
                        <tr>
                          <th className="ms-textAlignLeft">Filename</th>
                          <th className="ms-textAlignLeft">Size</th>
                          <th className="ms-textAlignLeft" width="20%">
                            Last modified time
                          </th>
                          <th className="ms-textAlignCenter" width="10%">
                            Remove
                          </th>
                        </tr>

                        {this.state.attachmentsList.map((file, ix) => {
                          return (
                            <tr key={ix}>
                              <td>{file[0].name}</td>
                              <td>
                                {(file[0].size / 1024).toFixed(2)}
                                kb{" "}
                              </td>
                              <td>{moment(file[0].lastModified).format("DD.MM.YYYY hh:mm")}</td>
                              <td className="ms-textAlignCenter">
                                <Icon iconName="Blocked2" onClick={() => this.deletAttachment(ix)} />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div style={{ width: "150px" }}>
                  <input
                    type="file"
                    id="file"
                    ref="fileUploader"
                    style={{ display: "none" }}
                    onChange={this.onAttachmentAdd}
                  />
                </div>
              </div>
            </div>
            <div className="registration-button">
              <PrimaryButton text="Add new file" onClick={() => this.refs.fileUploader.click()} />
            </div>
          </div>
        );
      };

      onAttachmentAdd = (e, value) => {
        let attachmentsList = [];
        let attachments = this.state.attachments ? this.state.attachments : [];

        // Shallow copy, since we need to reset the hidden input element, to be
        // abel to re-add the same file twice.

        const files = Object.assign({}, e.target.files);

        if (parseInt(files[0].size / 1024) > 2000) {
          toast.error(`Sorry, the file is too big. Max size is 2Mb.`);
          this.refs.fileUploader.value = "";
          return;
        }

        attachments = _.concat(attachments, files);

        for (let i = 0; i < attachments.length; i++) {
          attachmentsList.push(attachments[i]);
        }

        this.setState({ attachments, attachmentsList });
        this.refs.fileUploader.value = "";
      };

      deletAttachment = (id) => {
        const { attachments, attachmentsList } = this.state;

        attachments.splice(id, 1);
        attachmentsList.splice(id, 1);

        this.setState({ attachments, attachmentsList });
      };

      deleteRegistrationElement = (index, valueIx) => {
        let goodsValue = this.state.goodsValues;
        let elements = this.state.goodsRegistrationElements;

        goodsValue[valueIx] = undefined;
        elements[index] = undefined;

        let goodsArrayIsEmpty = true;
        elements.forEach((item, key) => {
          if (item !== undefined) {
            goodsArrayIsEmpty = false;
          }
        });

        if (goodsArrayIsEmpty) {
          this.setState({ goodsRegistrationElements: [], goodsValues: [] });
        } else {
          this.setState({ goodsRegistrationElements: elements });
        }
      };

      createSaveForm_CancleClick = (value) => {
        this.props.history.goBack();
      };

      isFormValid = () => {
        const { goodsValues, errorMessage } = this.state;
        let isValid = true;
        if (errorMessage && Object.isArray(errorMessage.goods)) {
          goodsValues.forEach((item, ix) => {
            errorMessage.goods[ix] = {};
            if (item.cll && item.cll <= 0 && item.pll && item.pll <= 0) {
              errorMessage.goods[ix].cll_pll = "- CLL or PLL must be of value 1 or greater";
              isValid = false;
            } else {
              errorMessage.goods[ix].cll_pll = "";
            }

            if (item.ldm && item.ldm <= 0 && item.cbm && item.cbm <= 0) {
              errorMessage.goods[ix].ldm_cbm = "- LDM or CBM must be of value 1 or greater";
              isValid = false;
            } else {
              errorMessage.goods[ix].ldm_cbm = "";
            }

            if (item.kg && item.kg <= 0) {
              errorMessage.goods[ix].kg = "- KG must be a of value 1 or greater";
              isValid = false;
            } else {
              errorMessage.goods[ix].kg = "";
            }
          });
          errorMessage.hasErrors = !isValid;
        }

        this.setState({ errorMessage });
        return isValid;
      };

      saveForm = () => {
        const {
          initials,
          orderReference,
          splitOrder,
          commentField,
          goodsValues,
          selectedCustomer,
          selectedCollectPlace,
          selectedDeliverPlace,
        } = this.state;

        let {
          collectTime,
          collectTimeLatest,
          collectDate,
          collectDateLatest,
          deliverTime,
          deliverTimeLatest,
          deliverDate,
          deliverDateLatest,
          collectPlaceContact,
          collectPlacePhone,
          deliverPlaceContact,
          deliverPlacePhone,
          transportType,
        } = this.state;

        let articles = [];
        goodsValues.forEach((item, key) => {
          if (item !== undefined) {
            articles.push(item);
          }
        });

        let collectPlace = {
          placeId: -1,
          name: undefined,
          address: undefined,
          postalCode: undefined,
          postalDistrict: undefined,
        };

        let deliverPlace = {
          placeId: -1,
          name: undefined,
          address: undefined,
          postalCode: undefined,
          postalDistrict: undefined,
        };

        if (selectedCollectPlace === undefined) {
          // let { manualCollectPlace } = this.state;

          collectPlace.name = this.state.manualCollectPlace.name;
          collectPlace.address = this.state.manualCollectPlace.address;
          collectPlace.postalCode = this.state.manualCollectPlace.postalCode;
          collectPlace.postalDistrict = this.state.manualCollectPlace.postalDistrict;

          // Create a random negative placeId just for local storage
          const newPlace = {
            placeId: Math.floor((1 + Math.random()) * 0x1000000) * -1,
            name: this.state.manualCollectPlace.name,
            address: {
              street: this.state.manualCollectPlace.address,
              postalCode: this.state.manualCollectPlace.postalCode,
              postalDistrict: this.state.manualCollectPlace.postalDistrict,
            },
          };

          this.storeDeliverPlace(newPlace);
        } else {
          collectPlace.placeId =
            this.state.selectedCollectPlace.placeId < 0 ? -1 : this.state.selectedCollectPlace.placeId;
          collectPlace.name = this.state.selectedCollectPlace.name;
          collectPlace.address = this.state.selectedCollectPlace.address.street;
          collectPlace.postalCode = this.state.selectedCollectPlace.address.postalCode;
          collectPlace.postalDistrict = this.state.selectedCollectPlace.address.postalDistrict;
        }

        if (selectedDeliverPlace === undefined) {
          // let { manualDeliverPlace } = this.state;

          deliverPlace.name = this.state.manualDeliverPlace.name;
          deliverPlace.address = this.state.manualDeliverPlace.address;
          deliverPlace.postalCode = this.state.manualDeliverPlace.postalCode;
          deliverPlace.postalDistrict = this.state.manualDeliverPlace.postalDistrict;

          // Create a random negative placeId just for local storage
          const newPlace = {
            placeId: Math.floor((1 + Math.random()) * 0x1000000) * -1,
            name: this.state.manualDeliverPlace.name,
            address: {
              street: this.state.manualDeliverPlace.address,
              postalCode: this.state.manualDeliverPlace.postalCode,
              postalDistrict: this.state.manualDeliverPlace.postalDistrict,
            },
          };

          this.storeCollectPlace(newPlace);
        } else {
          deliverPlace.placeId =
            this.state.selectedDeliverPlace.placeId < 0 ? -1 : this.state.selectedDeliverPlace.placeId;
          deliverPlace.name = this.state.selectedDeliverPlace.name;
          deliverPlace.address = this.state.selectedDeliverPlace.address.street;
          deliverPlace.postalCode = this.state.selectedDeliverPlace.address.postalCode;
          deliverPlace.postalDistrict = this.state.selectedDeliverPlace.address.postalDistrict;
        }

        collectDate = `${moment(collectDate).format("YYYY.MM.DD")} ${collectTime}`;
        collectDateLatest = `${moment(collectDateLatest).format("YYYY.MM.DD")} ${collectTimeLatest}`;

        deliverDate = `${moment(deliverDate).format("YYYY.MM.DD")} ${deliverTime}`;
        deliverDateLatest = `${moment(deliverDateLatest).format("YYYY.MM.DD")} ${deliverTimeLatest}`;

        let orderObject = {
          splitOrder: splitOrder,
          customerId: selectedCustomer,
          collectPlace: collectPlace,
          collectDate: collectDate,
          collectDateLatest: collectDateLatest,
          deliverDate: deliverDate,
          deliverDateLatest: deliverDateLatest,
          deliverPlace: deliverPlace,
          articles: articles,
          initials: initials,
          orderReference: orderReference,
          comment: commentField,
          collectPlaceContact: collectPlaceContact,
          collectPlacePhone: collectPlacePhone,
          deliverPlaceContact: deliverPlaceContact,
          deliverPlacePhone: deliverPlacePhone,
          transportType: transportType,
          preadvicePhone: this.state.preadvicePhone,
        };

        this.props.functions.data.createOrder(orderObject, this.state.attachments);
      };

      getCustomerNoAsOptions = () => {
        const { customers } = this.props.auth;
        let options = [];
        Object.keys(customers).forEach(function(key) {
          options.push({ key: customers[key], text: key });
        });
        return options;
      };

      getCustomerLocationsAsOptions = () => {
        const { customerLocations } = this.props.data;
        let options = [];

        if (customerLocations === undefined) {
          return null;
        }
        _.sortBy(customerLocations, "name").forEach((customer) => {
          options.push({ key: customer.id, text: customer.name });
        });

        const collectDeliverPlaceFromLocalStorage = this.getPlaceFromLocalStorage();
        if (collectDeliverPlaceFromLocalStorage !== undefined && isArray(collectDeliverPlaceFromLocalStorage)) {
          _.sortBy(collectDeliverPlaceFromLocalStorage, "name").forEach((customer) => {
            options.push({ key: customer.placeId, text: customer.name });
          });
        }

        return options;
      };

      customerOnChange = (value) => {
        this.setState({ selectedCustomer: value.key });
        this.props.functions.data.getCustomerLocations(value.key);
      };

      collectPlaceOnChanged = (value) => {
        if (value !== undefined) {
          const { customerLocations } = this.props.data;

          const storagePlaces = this.getPlaceFromLocalStorage();
          const places = _.concat(customerLocations, storagePlaces);

          if (customerLocations !== undefined) {
            let customer = _.find(places, (o) => {
              return o.id === value.key;
            });

            let manualCollectPlace = {
              name: customer.name,
              address: customer.address.street,
              postalCode: customer.address.postalCode,
              postalDistrict: customer.address.postalDistrict,
            };

            this.setState({
              selectedCollectPlace: customer,
              manualCollectPlace: manualCollectPlace,
            });
          }
        }
      };

      deliverPlaceOnChanged = (value) => {
        if (value !== undefined) {
          const { customerLocations } = this.props.data;
          const storagePlaces = this.getPlaceFromLocalStorage();
          const places = _.concat(customerLocations, storagePlaces);

          if (customerLocations !== undefined) {
            let customer = _.find(places, (o) => {
              return o.id === value.key;
            });

            let manualDeliverPlace = {
              name: customer.name,
              address: customer.address.street,
              postalCode: customer.address.postalCode,
              postalDistrict: customer.address.postalDistrict,
            };

            this.setState({
              selectedDeliverPlace: customer,
              manualDeliverPlace: manualDeliverPlace,
            });
          }
        }
      };

      onPreadvicePhoneChange = (preadvicePhone) => {
        this.setState({
          preadvicePhone,
        });
      };

      storeCollectPlace = (place) => {
        this.storePlaceInLocalStorage(place);
      };

      storeDeliverPlace = (place) => {
        this.storePlaceInLocalStorage(place);
      };

      storePlaceInLocalStorage = (place) => {
        if (place.name !== undefined || (place.address.street !== undefined && localStorage !== undefined)) {
          const storageObject = localStorage.getItem("collectDeliverPlace");
          let storage = [];
          if (storageObject !== null) {
            storage = JSON.parse(storageObject);
          }

          if (_.findIndex(storage, place) <= 0) {
            storage.push(place);
            localStorage.setItem("collectDeliverPlace", JSON.stringify(storage));
          }
        }
      };

      getPlaceFromLocalStorage = () => {
        let storage = [];
        const storageObject = localStorage.getItem("collectDeliverPlace");
        if (storageObject !== undefined && storageObject !== null) {
          storage = JSON.parse(storageObject);
          if (storage !== undefined && storage !== null) {
            storage.forEach((object) => {
              object.id = object.placeId;
            });
          }
        }

        return storage;
      };

      setTransportType = (e, value) => {
        this.setState({ transportType: value.key });
      };

      validateGoodsLine = (line, valid) => {
        const invalidGoodsLines = Object.assign({}, this.state.invalidGoodsLines);
        invalidGoodsLines[line] = valid;
        this.setState({ invalidGoodsLines });
      };

      isGoodsLineValid = () => {
        let isValid = true;
        Object.keys(this.state.invalidGoodsLines).forEach((item, ix) => {
          if (this.state.invalidGoodsLines[item] === false) {
            isValid = false;
          }
        });
        return isValid;
      };

      isDatesValid = () => {
        if (
          this.state.collectDate === undefined ||
          this.state.collectDateLatest === undefined ||
          this.state.deliverDate === undefined ||
          this.state.deliverDateLatest === undefined
        ) {
          return false;
        }
        return true;
      };

      isTimeInvalid = () => {
        const { isTimeValid } = this.state;
        let isInvalid = false;
        if (isTimeValid && Array.isArray(isTimeValid)) {
          Object.keys(isTimeValid).forEach((item, key) => {
            if (isTimeValid[item] === false) {
              isInvalid = true;
            }
          });
        }
        return isInvalid;
      };
    }
  )
);
