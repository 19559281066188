import React, { Component } from "react";
import { VERSION } from "version.js";

export default class Version extends Component {
  render() {
    if (this.props.numberOnly) {
      return VERSION;
    }

    if (this.props.full) {
      return <div>Version {VERSION} - Locus Logistics &copy; 2020</div>;
    }

    return <div>Version {VERSION} - Locus Logistics &copy; 2020</div>;
  }
}
