import React, { Component } from "react";
import { TextField } from "office-ui-fabric-react/lib/TextField";

import "./TimePicker.css";

export default class TimePicker extends Component {
  constructor(props) {
    super(props);

    this.timeRegexp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    this.updateTimeout = undefined;
    this.state = {
      touched: false,
      errorMessage: undefined,
      value: this.props.value ? this.props.value : "00:00"
    };
  }
  render() {
    return (
      <div className="timepicker-body">
        <div className="timepicker-label">
          {this.props.label && this.props.label}
        </div>
        <div className="timepicker-container">
          <div>
            <TextField
              value={this.state.value}
              onChange={this.onChange}
              onFocus={e => {
                if (!this.props.readOnly) {
                  if (this.state.touched === false) {
                    this.setState({
                      value: "",
                      touched: true
                    });
                  }
                }
              }}
              onBlur={e => {
                if (this.state.touched === true && this.state.value === "") {
                  const value = "00:00";
                  this.validate(value);
                  this.setState({ value, touched: false });
                }
              }}
              onKeyUp={ev => this.onKeyUp(ev, ev.target.value)}
              readOnly={this.props.readOnly ? true : false}
            />
          </div>
        </div>
        <div className="timepicker-error">{this.state.errorMessage}</div>
      </div>
    );
  }
  onChange = (e, value) => {
    if (
      (value.indexOf(":") === 2 && value.length > 5) ||
      (value.indexOf(":") === -1 && value.length > 4)
    ) {
      return;
    }

    const newValue = this.addColon(value);
    if (this.validate(newValue)) {
      this.setState({
        value: newValue,
        oldValue: this.state.value,
        errorMessage: undefined
      });
    } else {
      this.setState({
        value: newValue,
        oldValue: this.state.value
      });
    }

    if (this.props.onChanged !== undefined) {
      this.props.onChanged(null, newValue);
    }
  };

  onKeyUp = (ev, value) => {
    if (ev.key === "Enter") {
      this.onChange(ev, value);
    }
  };

  onBlur = ev => {
    let value = ev.target.value;
    if (!this.validate(value)) {
      ev.preventDefault();
      ev.target.focus();
    } else {
      this.onChange(ev, value);
    }
  };

  validate = value => {
    if (!this.timeRegexp.test(value)) {
      this.setState({
        errorMessage: "TimeFormat must be in HH:MM and between 00:00 and 23:59"
      });
      this.props.onValidation(false);
      return false;
    } else {
      this.setState({
        errorMessage: ""
      });
    }
    this.props.onValidation(true);
    return true;
  };

  addColon = value => {
    if (value.length >= 4 && value.indexOf(":") === -1) {
      value = `${value.substr(0, 2)}:${value.substr(2, 2)}`;
      return value;
    } else if (value.length > 5 && value.indexOf(":") >= 0) {
      value = `${value.substr(0, 2)}:${value.substr(2, 2)}`;
      return value;
    } else {
      return value;
    }
  };
}
