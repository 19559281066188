import React, { Component } from "react";
import { connect } from "react-redux";
import { mapDispatchToProps } from "reduxstore";
import { withRouter } from "react-router-dom";
import "./Menu.css";
import "./logo32.png";
import Logo from "./Logo";

import { SearchForm } from "components";
// import { LocusCard } from "locuscard";
import {
  Persona,
  PersonaSize,
  PersonaPresence,
  Callout,
  Spinner,
  SpinnerSize
} from "office-ui-fabric-react";

const mapStateToProps = state => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class Menu extends Component {
      constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.newOrderIsActive = false;
        this.userDetails = {
          // imageInitials: "AL",
          text: user.email,
          secondaryText: user.RealName
        };
        this.searchButtonElement = undefined;
        this.state = {
          searchFormVisible: false
        };
      }
      componentDidUpdate = (prevProps, prevState) => {
        if (
          prevProps.data.isSearch === true &&
          this.props.data.isSearch === false
        ) {
          this.setState({
            searchFormVisible: false
          });
        }
      };
      render() {
        this.newOrderIsActive =
          this.props.location.pathname.includes("/create/order") > 0 &&
          !this.state.searchFormVisible
            ? true
            : false;
        return (
          <div>
            <div className="menu-container">
              <div className="menu-content-tflogo">
                {this.props.data.isSearch ? (
                  <div className="menu-content-spinner">
                    <Spinner size={SpinnerSize.large} />
                  </div>
                ) : (
                  <div className="menu-content-image">
                    <img src="/logo32.png" alt="Logo" />
                  </div>
                )}
              </div>
              <div className="menu-content-left ms-fontSize-xl">
                <Logo width="70" height="50" />
              </div>
              <div className="menu-content-center">
                <div className="menu-items-container ms-fontSize-l ms-fontWeight-semibold">
                  <div
                    className={`menu-item menu-item-hover noselect ${this.state
                      .searchFormVisible && "menu-item-selected"}`}
                    onClick={() => {
                      this.setState({
                        searchFormVisible: true
                      });
                    }}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    <span
                      ref={searchButton =>
                        (this.searchButtonElement = searchButton)
                      }
                    >
                      Search
                    </span>
                    <Callout
                      gapSpace={0}
                      className="callout-transparent"
                      target={this.searchButtonElement}
                      onDismiss={() => {
                        this.setState({
                          searchFormVisible: !this.state.searchFormVisible
                        });
                      }}
                      setInitialFocus={true}
                      hidden={!this.state.searchFormVisible}
                      isBeakVisible={false}
                    >
                      <SearchForm />
                    </Callout>
                  </div>

                  <div
                    className={`menu-item menu-item-hover noselect  ${this
                      .newOrderIsActive && "menu-item-selected"}`}
                    onClick={() => {
                      this.props.history.push("/create/order");
                    }}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    New order
                  </div>
                </div>
              </div>
              <div className="menu-content-right menu-content-persona">
                <div
                  className="menu-person-frame right-aligned"
                  onClick={() => this.Logout()}
                >
                  <Persona
                    className="menu-content-persona-text"
                    {...this.userDetails}
                    size={PersonaSize.size32}
                    presence={PersonaPresence.none}
                    hidePersonaDetails={true}
                  />
                  <div className="menu-person-name">
                    {this.props.auth.user.email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      Logout = () => {
        /*
        Request logout from props function 
      */
        this.props.functions.application.showLogoutDialog(
          !this.props.application.showLogoutDialog
        );
      };
    }
  )
);
