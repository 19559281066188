import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { mapDispatchToProps } from "reduxstore";
import XLSX from "xlsx";
import moment from "moment";
import {
  DetailsList,
  DetailsListLayoutMode,
  CheckboxVisibility,
  ContextualMenu,
  DirectionalHint,
  Callout,
  Selection,
  SearchBox,
  DefaultButton,
} from "office-ui-fabric-react";
import { LocusCard } from "locuscard";
import { Helmet } from "react-helmet";
import { APPLICATION_NAME } from "constants/variables";
import "./OrderList.css";

const mapStateToProps = (state) => {
  return state;
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class OrderList extends Component {
      constructor(props) {
        super(props);

        this.DetailsListRef = undefined;
        this.state = {
          contextMenuTarget: undefined,
          contextMenuVisible: false,
          contextMenuItem: undefined,
          balloonTipHidden: true,
          balloonTipTarget: undefined,
          balloonTipContent: undefined,
          selection: new Selection(),
        };

        this.columns = [
          {
            key: "orderNo",
            name: "OrderNo",
            fieldName: "orderNo",
            isRowHeader: true,
            isResizable: true,
            maxWidth: 150,
            data: "string",
          },
          {
            key: "orderId",
            name: "OrderId",
            fieldName: "orderId",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            maxWidth: 150,
            data: "string",
          },
          {
            key: "productType",
            name: "Product Type",
            fieldName: "machineType",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            maxWidth: 150,
            data: "string",
          },
          {
            key: "serialNo",
            name: "Serial No.",
            fieldName: "serialNo",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            maxWidth: 150,
            data: "string",
          },
          {
            key: "collectPlace",
            name: "Collect Place",
            fieldName: "collectPlace",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            minWidth: 100,
            maxWidth: 300,
            data: "string",
          },
          {
            key: "collectDate",
            name: "Collect Date ",
            fieldName: "collectDate",
            isRowHeader: true,
            isCollapsable: false,
            minWidth: 150,
            maxWidth: 150,
            data: "string",
          },
          {
            key: "deliverPlace",
            name: "Deliver Place",
            fieldName: "deliverPlace",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            data: "string",
          },
          {
            key: "deliverPlaceCode",
            name: "Deliver ZIP",
            fieldName: "deliverPlacePostalCode",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            minWidth: 100,
            maxWidth: 100,
            data: "string",
          },
          {
            key: "deliverDate",
            name: "Deliver Date",
            fieldName: "deliverDate",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            minWidth: 150,
            maxWidth: 150,

            data: "string",
          },
          {
            key: "status",
            name: "Status",
            fieldName: "status",
            isRowHeader: true,
            isResizable: true,
            isCollapsable: false,
            minWidth: 250,
            maxWidth: 250,

            data: "string",
            onRender: (item) => {
              let statusIx = this.props.data.statusConfig.findIndex((statusItem, ix) => {
                if (statusItem.subStatus === -1) {
                  return statusItem.id === item.status;
                } else {
                  return statusItem.id === item.status && statusItem.subStatus === item.subStatus;
                }
              });
              return (
                <div>
                  <div>{statusIx > -1 ? this.props.data.statusConfig[statusIx].description : ""}</div>
                </div>
              );
            },
          },
          {
            key: "stausComment",
            name: "Status Comment",
            fieldName: "stausComment",
            isMultiline: true,
            isRowHeader: true,
            isResizable: false,
            isCollapsable: false,
            data: "string",
            onRender: (item) => {
              if (item.stausComment.length > 0) {
                return (
                  <i
                    alt="Status"
                    className="ms-Icon ms-Icon--CannedChat"
                    onMouseEnter={(ev) => {
                      this.setState({
                        balloonTipHidden: false,
                        balloonTipTarget: ev.target,
                        balloonTipContent: item.stausComment,
                      });
                    }}
                    onMouseLeave={() =>
                      this.setState({
                        balloonTipHidden: true,
                        balloonTipTarget: undefined,
                      })
                    }
                  />
                );
              }
              return <i className="ms-Icon ms-Icon--Chat" />;
            },
          },
          {
            key: "publicComment",
            name: "Instructions",
            fieldName: "publicComment",
            isMultiline: true,
            isRowHeader: true,
            isResizable: false,
            isCollapsable: false,
            data: "string",
            onRender: (item) => {
              if (item.publicComment.length > 0) {
                return (
                  <i
                    className="ms-Icon ms-Icon--CannedChat"
                    onMouseEnter={(ev) => {
                      this.setState({
                        balloonTipHidden: false,
                        balloonTipTarget: ev.target,
                        balloonTipContent: item.publicComment,
                      });
                    }}
                    onMouseLeave={() =>
                      this.setState({
                        balloonTipHidden: true,
                        balloonTipTarget: undefined,
                      })
                    }
                  />
                );
              }
              return <i className="ms-Icon ms-Icon--Chat" />;
            },
          },
        ];

        this.contextMenuItems = [
          {
            propertyName: "Open",
            name: "Open order",
            key: "Open",
            onClick: (ev) => {
              this.onItemInvoked();
            },
          },
        ];
      }

      componentDidUpdate = (prevProps, prevState) => {
        const { selection } = this.state;

        if (selection !== prevState.selection) {
          this.setState({ selection: selection });
        }
      };

      render() {
        const { filteredOrders, orders } = this.props.data;
        let selection = new Selection();
        selection.setAllSelected(false);
        selection.setIndexSelected(this.props.application.orderDetails.selectedIndex, true, true);

        var activeItemTimout = undefined;

        let cardTitle =
          this.props.data.filteredOrders !== undefined && this.props.data.filteredOrders.length > 0
            ? `Orders - Result: ${this.props.data.filteredOrders.length} order(s)`
            : `Orders`;

        return (
          <div>
            <ContextualMenu
              target={this.state.contextMenuTarget}
              hidden={!this.state.contextMenuVisible}
              items={this.contextMenuItems}
              directionalHint={DirectionalHint.bottomAutoEdge}
              onDismiss={() => {
                this.setState({ contextMenuVisible: false });
              }}
            />

            <Callout
              className="comment-transparent"
              target={this.state.balloonTipTarget}
              setInitialFocus={true}
              hidden={this.state.balloonTipHidden}
              isBeakVisible={true}
              directionalHint={DirectionalHint.bottomRightEdge}
            >
              <div>
                {this.state.balloonTipContent &&
                  this.state.balloonTipContent.split(/(?:\r\n|\r|\n)/g).map((item, key) => {
                    return <div key={key}>{item}</div>;
                  })}
              </div>
            </Callout>

            <Helmet>
              <title>{`${APPLICATION_NAME} - ${cardTitle}`}</title>
            </Helmet>

            <LocusCard title={cardTitle}>
              <div className="orderlist-buttongroup-top">
                <div>
                  <SearchBox
                    placeholder="Filter search"
                    value={this.props.data.filterQuery}
                    disabled={orders === undefined || orders.length <= 0}
                    onChange={(e, value) => this.onSearch(value)}
                    underlined={true}
                  />
                </div>
                <div>
                  <DefaultButton
                    disabled={filteredOrders === undefined || filteredOrders.length <= 0}
                    text="Export to Excel"
                    onClick={this.exportToExcel}
                  />
                </div>
              </div>
              <DetailsList
                componentRef={this.UpdateDetailsListRef}
                items={filteredOrders}
                columns={this.columns}
                checkboxVisibility={CheckboxVisibility.hidden}
                layoutMode={DetailsListLayoutMode.justified}
                setKey="orderNo"
                onActiveItemChanged={(item) => {
                  if (activeItemTimout !== undefined) {
                    clearTimeout(activeItemTimout);
                  }
                  activeItemTimout = setTimeout(() => {}, 500);
                }}
                onItemInvoked={(item, index) => {
                  this.onItemInvoked(item, index);
                }}
                onItemContextMenu={(item, index, ev) =>
                  this.setState({
                    contextMenuItem: item,
                    contextMenuVisible: true,
                    contextMenuTarget: ev,
                  })
                }
              />
            </LocusCard>
          </div>
        );
      }

      UpdateDetailsListRef = (list) => {
        this.DetailsListRef = list;
      };

      onItemInvoked = (item, index) => {
        if (!item) {
          item = this.state.contextMenuItem;
        }
        this.props.history.push(`/details/${item.orderNo}`);
      };

      exportToExcel = () => {
        const { filteredOrders } = this.props.data;
        let excelObject = [];
        let colsDescription = [];

        let exportOrders = [
          {
            OrderNo: undefined,
            OrderId: undefined,
            ProductType: undefined,
            SerialNo: undefined,
            CollectPlace: undefined,
            CollectPlaceStreet: undefined,
            CollectPlacePostalCode: undefined,
            CollectPlacePostalDistrict: undefined,
            CollectDate: undefined,
            DeliverPlace: undefined,
            DeliverPlaceStreet: undefined,
            DeliverPlacePostalCode: undefined,
            DeliverPlacePostalDistrict: undefined,
            DeliverDate: undefined,
            Status: undefined,
            StausComment: undefined,
            PublicComment: undefined,
          },
        ];
        filteredOrders.forEach((item) => {
          let statusIx = this.props.data.statusConfig.findIndex((statusItem, ix) => {
            if (statusItem.subStatus === -1) {
              return statusItem.id === item.status;
            } else {
              return statusItem.id === item.status && statusItem.subStatus === item.subStatus;
            }
          });

          exportOrders.push({
            OrderNo: item.orderNo,
            OrderId: item.orderId,
            ProductType: item.machineType,
            SerialNo: item.serialNo,
            CollectPlace: item.collectPlace,
            CollectPlaceStreet: item.collectPlaceStreet,
            CollectPlacePostalCode: item.collectPlacePostalCode,
            CollectPlacePostalDistrict: item.collectPlacePostalDistrict,
            CollectDate: item.collectDate,
            DeliverPlace: item.deliverPlace,
            DeliverPlaceStreet: item.deliverPlaceStreet,
            eliverPlacePostalCode: item.deliverPlacePostalCode,
            DeliverPlacePostalDistrict: item.deliverPlacePostalDistrict,
            DeliverDate: item.deliverDate,
            Status: statusIx > -1 ? this.props.data.statusConfig[statusIx].description : "",
            StatusComment: item.stausComment,
            PublicComment: item.publicComment,
          });
        });

        for (let prop in exportOrders[0]) {
          colsDescription.push(prop);
        }
        excelObject.push(colsDescription);
        exportOrders.forEach((item, key) => {
          if (key > 0) {
            let dataItem = [];
            for (let prop in item) {
              dataItem.push(item[prop]);
            }
            excelObject.push(dataItem);
          }
        });

        var worksheet = XLSX.utils.aoa_to_sheet(excelObject);
        var new_workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(new_workbook, worksheet, "CustomerWeb");
        XLSX.writeFile(new_workbook, `CustomerWebOrderList_${moment().format("YYYYDDMM_HHmm")}.xlsx`);
      };

      onSearch = (value) => {
        const { orders } = this.props.data;
        this.props.functions.data.filterSearch(value, orders);
      };
    }
  )
);
